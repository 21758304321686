@charset "utf-8";

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300;400;500;700&display=swap');

body, p{margin: 0; padding: 0;font-size:16px; font-family: 'Noto Sans KR', sans-serif;color: #333; }
button{text-decoration: none; color: #333;}
input{ outline: none;}
::placeholder{color: #ddd;}

/* 티징헤더 */
#header_realtor{ width:100%; height: 80px; display: flex; justify-content: space-between;align-items:center;}
#header_realtor button{ display: block;}
#header_realtor .logo{width: 65px; margin-left: 8%;}
#header_realtor button:last-child{margin-right: 8%;font-weight: 500;color: #7e4612;background: #FFDE00;padding: 6px 14px;border-radius: 20px;}
#header_realtor button img{ width: 100%;}

/* 로그인팝업 */
.login_pop{ display: none; position: absolute;top: 64px;right: 7%; width: 350px;background-color: #fff;border: 1px solid #ddd;border-radius: 4px;padding: 10px 20px;box-sizing: border-box; box-shadow: 1px 2px 6px #d8d8d8;}
.login_pop .login_wrap > p{display: inline-block; font-weight: 700;color: #7e4612;}
.login_pop .login_wrap .login_input{display: flex;justify-content:space-between; margin-top: 20px;}
.login_pop .login_wrap .login_input span{width:80px; font-size: 0.875em;font-weight: 700;padding-top:5px}
.login_pop .login_wrap .login_input input{min-width:230px;max-width:210px;width:210px;height:34px;line-height:32px;margin:0px; border: 1px solid #ddd; padding: 6px; border-radius: 2px;}
  /* 버튼 */
.login_pop .close{ text-align: right;display: inline-block;width: 84%;}
.login_pop .close img{ cursor: pointer;}
.login_pop .btn_realtor{ margin-top: 30px; text-align: center;}
.login_pop .login_btn{ border: none;background: #ffde00;color: #7e4612;padding: 10px 130px;border-radius: 6px;font-weight: 700; cursor: pointer;}

/* 메인배너 */
.main_banner{background-color: #f8f8f8; height: 500px; display: flex;justify-content: space-between;align-items: center;}
.main_banner .banner_left{ margin-left: 8%;}
.main_banner .banner_left p{font-size: 1.5em; margin-bottom: 40px;}
.main_banner .banner_left p span{ font-weight: 700; color: #7e4612;}
.main_banner .banner_left a{ display: inline-block;background-color: #FFDE00;padding: 12px 50px; border-radius: 14px;font-weight: 700; box-shadow: 2px 2px 6px #eee;}
.main_banner .banner_right{ margin-right: 8%; height: 330px;}
.main_banner .banner_right img{height: 100%;}

/* main_con con */
.con_realtor{ text-align: center; margin: 90px 0 120px;}
.con_realtor .tit{font-size: 1.575em; margin-bottom: 80px;}
.con_realtor .tit span{font-size: 1.5em; font-weight: 700; color: #7e4612;}
.img_box{ display: inline-block; width: 520px;}
.img_box img{ width: 100%;}

.con_02 .img_box{ width: 700px;}

.con_03{ border-top: 20px solid #f8f8f8; padding-bottom: 20px;}
.con_03 .tit{font-size: 2em;font-weight: 700;margin-top: 80px;}
.con_03 .img_wrap{display: flex;justify-content:center;}
.con_03 .img_wrap .img{margin-right: 120px;}
.con_03 .img_wrap .img img{width: 260px;box-shadow: 1px 1px 10px #eee;border-radius: 50%;}
.con_03 .img_wrap .img:last-child{margin-right: 0;}
.con_03 .img_wrap .img p{font-size: 1.8em; font-weight: 700; margin-top: 30px; text-align: center;}

.con_04{ background-color: #65401E; padding: 50px; margin-bottom: 0;text-align:center;}
.con_04 p{color: #fff; font-weight: 500; font-size: 1.875em; margin-bottom: 40px;}
.con_04 a{display: inline-block; background-color: #FFDE00; padding: 10px 50px; border-radius: 14px;font-size: 1.3em;font-weight: 700;}

/* 티징푸터 */
#footer{background-color: #f8f8f8; padding: 50px 5% 30px;}
#footer .c_name{ font-size: 1.3em; font-weight: 700; color: #555; margin-bottom: 20px;}
#footer .c_info{ font-size: 0.9em; font-weight: 300; color: #555; line-height: 1.6;}
#footer .copy{ margin: 50px 0 0; font-size: 0.875em; font-weight: 300; text-align: center;}



/* 헤더 */
#header_add{ border-bottom: 1px solid #ddd;padding:20px;text-align:center;}
#header_add button:last-child{ background-color: transparent; border: 2px solid #FFDE00;color: #555; font-weight: 400;padding: 6px 28px;}
/* con */
.realtor_add_con{margin: 150px 20px;text-align: center;padding-bottom:30px}
.realtor_add_con .con_tit{margin-top: 20px; margin-bottom: 80px;font-size: 32px;font-weight: 700;}
/* 버튼 */
.realtor_add_con .btn_wrap{display: flex;justify-content:center;}
.realtor_add_con .btn_wrap .btn2{margin-right:30px;}
.realtor_add_con .btn_wrap .btn2:last-child{margin-right:0px;}
.realtor_add_con .btn2 a{ display: block; background-color: #7E4612;padding: 60px 90px;font-size: 24px; font-weight: 700;color: #fff;border-radius: 20px;box-shadow: 4px 4px 8px #ddd;}
.realtor_add_con .btn2 p{ margin-top: 30px;}
.realtor_add_con .btn2 p img{width: 14px;vertical-align: top;}
.realtor_add_con .btn2 p span{text-align: left;padding-left: 6px;}

#header_add .log-name {position: absolute;top:25px;right:160px;text-align:right;font-weight: 600;}
#header_add .btn-logout {position: absolute;top:20px;right:20px;}