/************************************
 *!!!! admin.css 내에 스타일 정의할 때에는 꼭 앞에 #contents를 붙입니다. (사용자 화면과 스타일 겹칠 수 있음.)
************************************/
#contents {
  min-width: calc(1600px - 240px);
}

/************************************
 ** INPUT STYLES
************************************/
.ad_contents input[type=number],
.ad_contents input[type=password],
.ad_contents input[type=tel],
.ad_contents input[type=text],
.ad_contents input[type=email],
.ad_contents input[type=date],
.ad_contents select,
.ad_contents textarea {
  height:40px !important;
  min-height: 40px !important;
  border-radius: 4px !important;
  padding-left: 10px !important;
  border: 1px solid #e2e3e5 !important;
  color: #333 !important;
  box-sizing: border-box !important;
}
.ad_contents textarea {
  height: auto !important;
}

.ad_contents input[type=checkbox] + label,
.ad_contents input[type=radio] + label {
  margin-left: 4px !important;
  margin-right: 20px !important;
}
.ad_contents input[type=radio] + label.mgr0 {
  margin-right: 0px !important;
}

.ad_contents .select-list .select-items select,
.ad_contents .select-list2 .select-items select {
  min-height:160px !important;padding:0 !important; background: none !important;
  overflow-y: auto !important;
}
.ad_contents .select-list .select-items option,
.ad_contents .select-list2 .select-items option  {
  padding: 4px 10px !important;
}
.ad_contents .day-box input {
  width: auto !important;
}

/************************************
 ** BUTTON STYLES
************************************/
#contents .btn, .ad_contents .btn, 
#contents .btn-danger, .ad_contents .btn-danger,
#contents .btn-right > button, .ad_contents .btn-right > button {
  display: inline-block;
  height:40px;
  min-height: 40px;
  min-width: 70px;
  font-size:15px;
  background-color: #258afe;
  border-radius: 4px;
  color: #fff;
  vertical-align: middle;
  padding:0px;
  line-height: 40px;
  cursor:pointer;
  text-align:center;
}
#contents .btn.black, .ad_contents .btn.black {
  background-color: #444;
}
#contents .btn.gray, .ad_contents .btn.gray {
  background: #fff; border:1px solid #ccc; color:#ccc !important;
}
#contents .btn.gray:hover, .ad_contents .btn.gray:hover {
  background: #fff; border:1px solid #3d7df2; color:#3d7df2 !important;
}
#contents .btn.trline, .ad_contents .btn.trline {
  background: #fff; border:1px solid #3d7df2; color:#3d7df2 !important;
  min-width:60px; width:40%;
}
#contents .btn.line, .ad_contents .btn.line {
  background: #fff; border:1px solid #3d7df2; color:#3d7df2 !important;
}
#contents .btn.line.disable {pointer-events:none;background-color:#eee;border:1px solid #eee;color:#fff !important;}

#contents .btn.line.black, .ad_contents .btn.line.black {
  background: #fff; border:1px solid #333; color:#333 !important;
}
#contents .btn a, .ad_contents .btn a,
#contents .btn-danger a, .ad_contents .btn-danger a {
  font-size: inherit;
  line-height: inherit;
  color: inherit !important;
  font-weight: inherit;
  letter-spacing: inherit;
}
#contents .list .btn.line.black, .ad_contents .list .btn.line.black {  height:34px; min-height:34px; line-height:32px; min-width:45px;
}
#contents .btn-right, .ad_contents .btn-right {
  display: flex;align-items: center;
}
#contents .select-list .select-btn-delete, .ad_contents .select-list .select-btn-delete, 
#contents .select-list .select-btn-new, .ad_contents .select-list.select-btn-new,
#contents .select-list .select-btn-add, .ad_contents .select-list .select-btn-add { color:#fff; font-size:15px;}

#contents .bottom_btn_wrap, .ad_contents .bottom_btn_wrap { display:flex; align-items: center;}
#contents .bottom_btn_wrap.two, .ad_contents .bottom_btn_wrap.two { justify-content: space-between; }
#contents .btn.line.adm_hide {pointer-events: none;background-color: #eee;border-color: #ddd; color:#666 !important;}
/************************************
 ** TABLE STYLES
************************************/
#contents table * {
  font-size: 15px;
  color: #666;
}
#contents .list {
  table-layout: fixed;
}
#contents .list th {
  height: 57px;
  line-height: 1.5;
  padding: 0 6px;
  font-weight: 600;
  color: #333;
}
#contents .list td {
  line-height: 1.5;
  height: 57px;
  padding: 6px;
  font-size: 15px;
}
#contents .list.bg_gray th,
#contents .list.bg_gray td {
  background-color: #f2f2f2;
}
#contents .list.bg_gray th {
  border-color: #ddd;
}
#contents .list td p,
#contents .list td label,
#contents .write td p,
#contents .write td label,
#contents .write .write-radio {
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  color: inherit;
  letter-spacing: inherit;
  line-height: inherit;
  text-decoration: inherit;
}
#contents .write td label {
  /* vertical-align: middle; */
}
#contents .write td,
#contents .write th {
  font-size: 15px;
  color: #666;
  line-height: 1.5;
  height: 57px;
  vertical-align: middle;
  font-weight: 300;
}
#contents .write th {
  font-weight: 600;
  color: #333;
  max-width: 270px;
  width: 180px;
  padding-left: 16px;
}
#contents .write-list .term-textarea textarea{height:100% !important; }
#contents .write-list .write-textarea textarea{height:100% !important; }
#contents .write .write-textarea textarea{height:100% !important; min-height:400px !important; }
#contents .write th { vertical-align: middle; }
#contents .write td textarea{height:200px !important;}
#contents .write td {padding:8px 15px; word-break: break-all}
#contents .write .write-radio { display: inline-flex; align-items: center; }
#contents .write td.write-radio { display: table-cell; }

#contents .even_color tr:nth-child(even) td {
  background-color:#eee;
  padding: 5px 20px;
  height: 20px;
}

#contents .write-list .write-radio input[type="radio"],
#contents .write .write-radio input[type="radio"],
#contents .write .write-radio input[type="checkbox"] {
  display: inline-block !important;
}
#contents .btn-center{display: flex;justify-content: center;align-items: center;}

#contents .search-box {
  padding: 10px 20px;
  line-height: normal;
}
#contents .search-area {display:flex; flex-direction: row; justify-content:space-between;}
#contents .search-box .datepicker_area { display: inline-flex; align-items: center;}
#contents .search-box .f-right { display: inline-flex; align-items: center; }
#contents .search-box.row-two{flex-wrap: wrap;}
#contents .search-box.row-two .wd100{width:100%;margin-top:10px;display: flex;justify-content: flex-end;}
#contents .size-box > div {
  align-items: center;
  font-size: 15px;
  font-weight: 300;
}
#contents .search-box select {
  width: auto !important;
}
#contents .search-box p { margin-right:10px; }
#contents .ml10 { margin-left: 10px; }
#contents .ml20 { margin-left: 20px; }
#contents .mr20 { margin-right: 20px; }
#contents .pd20 { padding: 20px; }
#contents .main-box.text { color:#666; font-size: 15px; font-weight: 300; }
#contents .wd200 { width:200px; }
#contents .wd300 { width:300px; }


#contents .login-form-box{display: flex;flex-direction: column;    justify-content: center; align-items: center;}
#contents .login-form-box .ml-none{margin-left:0}
#contents .text_left {
  text-align: left;
}
#contents select.short_select {
  max-width: 200px;
}
#contents select.short_select2 {
  max-width: 300px;
}
#contents .search-box select.short_select {
  max-width: 120px;
}

/************************************
 ** 체크박스, 라디오박스
************************************/
#contents .write .write-radio input[type="radio"],
#contents .write input[type="checkbox"],
#contents .list input[type="checkbox"] {
  cursor: pointer;
  width: 18px;
  height: 18px;
  margin-left: 10px;
  vertical-align: text-top;
  background: url("../images/check-off.png") left/18px no-repeat;
}
#contents .write .write-radio input[type="radio"]:checked,
#contents .write input[type="checkbox"]:checked,
#contents .list input[type="checkbox"]:checked {
  background-image: url("../images/check-on.png");
}
#contents .board-list input[type="checkbox"] {
  display: inline-block;
  margin-left: 0;
  vertical-align: middle;
}
#contents .write-radio > label {
  display: inline-flex;
  align-items: center;
}
#contents .write-radio > label + label {
  margin-left: 10px;
}
#contents .write-radio > label > input[type="radio"],
#contents .write-radio > label > input[type="checkbox"] {
  margin-left: 0 !important;
  margin-right: 6px;
}

/************************************
 ** 회원관리 상세 STYLES
************************************/
#contents .menu.user_manage { border-radius: 4px;}
#contents .menu.user_manage a { height: 40px; font-size: 15px;}
#contents .table_title { font-size: 20px; margin-bottom: 10px; }
#contents .main-box > textarea { border: none; }

/************************************
 ** SCROLL STYLES
************************************/
#contents *::-webkit-scrollbar {
  opacity: 0;
  width: 10px;
  height: 10px;
}
#contents *::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  height: 10px;
  border-radius: 2px;
}
#contents *::-webkit-scrollbar-track {
  background-color: transparent;
  width: 1px;
}
#contents *::-webkit-scrollbar-button {
  background-color: transparent;
  width: 0;
  height: 0;
}

.image-block-area {
  width: 120px;
  height: 156px;
  margin-bottom: 10px;
  background: #fff;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #EBE8E8;
}
.image-block {
  width:100%;
  display:flex;
  flex-wrap: wrap;
  justify-content: left;
}
.image-block .image-box {
  width:120px;
  height:156px;
  max-height:100%;
  overflow: hidden;
  position: relative;
}
.image-block .image-box .btn-delete-image {
  width:20px;
  height:20px;
  position: absolute;
  right:2px;
  top:2px;
  z-index: 10;
}

table.write {width:100%;}
.write tr.image-area {width:100%;max-height:180px;}
.write tr.image-area th.image-title, .write tr.image-area td.image-block {min-height:180px;}
.write td input.email-box {width:400px;}
.write td input.name-box {width:200px;}
.write td input.password-box {width:150px;}
.write td select.region-box {width:150px;}
.write td select.region2-box {width:120px;}
.write td input.mobile-box {width:200px;}

.write td select.bank-box {width:180px;}
.write td input.account-box {width:300px;}
.write td input.zipcode-box {width:120px;background-color: #eee;}
.write td input.address1-box {width:500px;margin-top:5px;background-color: #eee;}
.write td input.address2-box {width:calc(100% - 530px);}

.write td input.title-box {width:300px;}
.write td input.price-box {width:150px;text-align:right;padding-right:10px;}

.write td input.room-box {width:90px;}
.write td.flex-block {display:flex;flex-direction:row;}
.write td input.minute-box {width:60px;text-align:right;padding-right:10px;}
.write td input.number-box {width:180px;text-align:right;padding-right:10px;}
.write td input.number2-box {width:120px;text-align:right;padding-right:10px;}
.write td select.select-box {width:250px;}
.write td select.trans-box {width:130px;}

.write td input.disable {pointer-events:none;background-color:#eee;}
.write td select.disable {pointer-events:none;background-color:#eee;}
#contents .write td input.check-box {vertical-align: middle;}
#contents .write .product_subtitle,#contents .write .product_subtitle2 {font-size:18px;font-weight:bold;}
#contents .write .product_subtitle:before{content:'◼︎';font-size:24px;margin-right:10px;vertical-align: middle;}
#contents .write .product_subtitle2 .text-title > .text-string:before {content:'◼︎';font-size:24px;margin-right:10px;vertical-align: middle;}

.one-line.line-left-row {display:flex;flex-direction:row;justify-content: left;margin-top:10px;}
.one-line.disable {display:none;}
.request-image {object-fit:cover;max-width:120px;max-height:156px;display:block;margin-left:auto;margin-right:auto}

.adm_hide {pointer-events: none;background-color: #eee;}
.warning {background-color:#f3b6a5;}