
:root {
    --font-color- : #555555;
    --main-color- : #FFDE00;
    --sub-color- : #f5f5f4;
    --sub-color2- : #dededd;
    --scroll-color- : #e2e2e2;
    --border-color- :#F4F4F5;
    --disabled-bg-color- : #fafafa;
    --disabled-font-color- : #aaaaaa;
    --title-color-: #444;
    --title-size-: 20px;
    --title-weight-: 500;
}

/* 공통 체크표시 */
.sub-wrap{  background:#f5f6f7;border-top:1px solid #e7e7e7;margin-bottom:-120px;padding-bottom:120px;}
.tit-line { display:block;text-align:center;font-size:30px;color:#000000;font-weight:500;text-decoration:underline;padding:40px 0 60px;font-size:20px;padding:20px 0 30px}

.btn-center { text-align:center;padding-top:40px;}
.btn.gray{ background: #e7f0f5;color: #000;}
.btn.yellow{ background:#ffe400;color:#1a2c5e;}
.btn.bg-yellow{ background:#ffe400;color:#1a2c5e;}
.mr0 { margin-right:0!important;}
.btn img { display:inline-block;margin:0 13px;}

.btn-center { text-align:center;padding-top:20px;}
.btn { height:40px;line-height:40px;width:45%;display:inline-block;text-align:center;font-size:16px;font-weight:500;color:#fff;margin:0 5px;border-radius:8px;background: #7edcff;padding:2px 0;}
.btn.list {height:30px;line-height:30px;font-size:14px;width:45%;font-weight:500;color:#fff;margin:0 5px;border-radius:8px;background: #7edcff;padding:0;}
.clr { clear:both;display:block; content:'';}
.btn.smalllist {height:24px;line-height:24px;font-size:14px;width:45%;font-weight:500;color:#fff;margin:5px 5px;border-radius:8px;background: #7edcff;padding:0;}

.layer-bg { display:none;position:fixed;width:100%;height:100%;top:0;left:0;z-index:11;background-color:rgba(0,0,0,.69)}
.layer-bg2 { display:none;position:fixed;width:100%;height:100%;top:0;left:0;z-index:13;background-color:rgba(0,0,0,.6)}
.layer-bg3 { position:absolute;width:100%;height:100%;top:0;left:0;z-index:3;background-color:rgba(0,0,0,.6)}
.layer-bg3 .report-link {width:100%;height:100%;color:white;position:absolute;top:43%;font-weight:500;font-size:16px;text-align:center;}

/* 레이어팝업공통사용 */
.layer-pop { min-height:80%;display:none;background:#fff;position:fixed;left:5%;transform:none;top:50%;transform: translateY(-50%);z-index:12;max-width:800px;
width:90%;box-sizing:border-box;font-size:16px;text-align:left;font-size:15px;padding-bottom:54px;}
.layer-pop.h_auto .layer_inner::-webkit-scrollbar{ display: none;} 
.layer-pop.full_pop{left:5%;height:80%;width:90%;border:solid 1px #ddd;}
.layer-pop.full_pop2{left:0;height:100%;width:100%;}
/* .layer-pop.full_pop3{margin: auto;left:0px;top:0px;-webkit-transform: translateY(0);transform: translateY(0%);height:100%;width:100%;} */
.layer-pop.full_pop3 {margin: auto;left: 50%;top: 50%;transform: translate(-50%, -50%);height: 100%;width: 100%;}
.layer-pop.h_auto{min-height:auto;}

.layer-pop .layer_tit{font-size:1.4em; font-weight: bold;height: 40px; line-height: 40px;padding: 10px 0 10px;position:fixed;top:0;width:100%; }
.layer-pop .layer_tit span{display:inline-block;}

.layer-pop .layer_inner{height: calc(100% - 60px);width:100%;}
.layer-pop .layer_inner .sel_wrap {display:flex;flex-direction:row;padding:5px;width:100%;}
.layer-pop .layer_inner .sel_wrap select {width:48%;}

.layer-pop .layer_inner .pop_subtit {font-weight:bold; font-size: 1.125em;text-align:left;margin:5px 10px;}
.layer-pop .layer_inner .pop_subtit .gray_txt{font-size: 0.8em; font-weight: normal;color:#666;}

.layer-pop .layer_inner .scroll-wrap{display: flex;flex-wrap:wrap;max-height:calc(100% - 82px);overflow-y:scroll;padding:0px 10px;}
.layer-pop .layer_inner .scroll-wrap li{width: 32%;margin-right: 1%;margin-top: 10px;}

.layer-pop .layer_inner .scroll-wrap2{flex-wrap:wrap;max-height:100%;overflow-y:scroll;width:100%;}
.layer-pop .layer_inner .scroll-wrap2 .con_group {width:calc(100% - 20px);padding:0px 10px;}

.layer-pop .layer_inner2{height: calc(100% - 60px);width:100%; padding:20px}

.layer-pop .btn_bottom{box-shadow:none;padding:0;position:fixed;bottom:0;}
.layer-pop .btn_bottom.one_btn .btn_style_yes {width:100%;}

.layer-pop .btm_txt{text-align:left;font-size:0.8em;color:#666; margin:10px 0px 15px 10px;}

.scroll-wrap, .scroll-wrap2 {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.scroll-wrap::-webkit-scrollbar, .scroll-wrap2::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}

.dan3{display: flex;justify-content: space-between;flex-wrap: wrap;justify-content: flex-start;margin:0px 5px 0px 10px;}
.dan3 li{width:32%;margin-right:1%;max-width:350px;margin-bottom:3px;}
.dan3 li:last-child{margin-right: 0;}
.con_box .txt01{font-size:1em; color:#666;margin:10px 0px 15px;}

.dan2{display: flex;justify-content: space-between;}
.dan2 select{width:48%;max-width:350px;}

.dan_input01 {display: flex;}

.dan_input01 .terms_chkpop{display:inline-block;color:#222; position:relative;width:48%;vertical-align:top;font-size: 13px;height: 45px;}
.dan_input01 .terms_chkpop .chk1+i{top:0px;}
.dan_input01 .terms_chkpop .chk1:checked+i:after{vertical-align:0px!Important;}
.dan_input01 .terms_chkpop .chk1{width:10px; height:10px;vertical-align:middle;display:none;}
.dan_input01 .terms_chkpop .chk1+i{display:inline-block; width:100%; height:55px;box-sizing:border-box}
.dan_input01 .terms_chkpop .chk1+i{pointer-events:none;}
.dan_input01 .terms_chkpop .chk1:checked+i{ text-align:center;}
.dan_input01 .terms_chkpop label { background:var(--sub-color-);color:#222;display:inline-block;position:relative;width:80px;
height:42px;position:absolute;top:0;left:0;line-height:42px;border-radius:5px;text-align: center;z-index:2;}
.dan_input01 .terms_chkpop label p { display:inline-block;float:right;margin-right:15px;}
.dan_input01 .terms_chkpop .chk1:checked+i+label {background-color:var(--main-color-);color:#222;font-weight:bold;}

.dan_input01 .mm_box{width:90px;text-align:center;}
.dan_input01 .mini_bar{width:30px;display:flex;align-items:center;}
.dan_input01 .danwe{width:50px;display:flex;align-items:center;text-align:right;}


.dan_input01 .all_btn{width:24%;margin-right:1%}
.dan_input01 .all_btn .terms_chk33{width:100%}
.dan_input01 .ipt_box{display:flex;align-items:center;}
.dan_input01 .ipt_box .danwe{width:100px}

.layer-pop.pop-condition {height:80%;padding:0px 10px;}

/* 필터 각각 팝업 */
.layer-pop .ipt_box{display: flex;align-items: center;padding:10px; margin-top: 5px; border-top: 1px solid #E9E9E9;}
.layer-pop .ipt_box .danwe{width:100px}

.layer-pop b{font-size:20px;margin-bottom:15px;display: block;padding-left:5%}

/* .layer-pop {text-align:center;} */
.layer-pop .btn{display: inline-block;text-align: center; color: #fff;    background: #7edcff;    margin: 0 5px;    border-radius: 8px;padding:2px 0;}
.layer-pop .top_close_btn{text-align:right;cursor:pointer}
.layer-pop .close-layer.btn_style_no {text-align:center;}
.layer-pop .popup_icon{margin-bottom:10px;}
.layer-pop .close_btn{text-align:center;}
.layer-pop .txt02{color:#797979;font-size:0.95em;}
.layer-pop .txt_str{color:#333;margin-bottom:1px;font-weight:500;font-size:0.95em;}
.layer-pop .center_wrap{height:100%;}
/* .layer-pop .close_btn{text-align:right;}
.layer-pop .close_btn img{width:20px;} */




@media only screen and (min-width: 800px) {
  .layer-pop.full_pop{left:50%;height: 90%;}
}

html, a, a:focus, a:active, a:link, a:visited {
    /* 화면을 길게 누르고 있을때 뜨는 팝업이나 액션시트를 제어 */
    -webkit-touch-callout:none;
    /* 링크를 터치했을때 나오는 기본 영역의 색상을 제어 */
    -webkit-tap-highlight-color:rgba(0, 0, 0, 0);
}

.mr_3per{margin-right:3%;}
.w3_per div{width: 30%; margin-right:2%}
.w3_per div:last-child{margin-right: 0;}
.w2_per div{width: 48%; margin-right:2%}
.w2_per div:last-child{margin-right: 0;}
.btn_bottom2{width:100%; max-width:800px;margin: 10px auto 10px;text-align:center;}
.btn_bottom{z-index:9;width: 100%;max-width: 800px;position: fixed;bottom: 0;left: 50%;transform: translateX(-50%);border-radius:30px 30px 0 0;display: flex;justify-content: center;}
.btn_bottom:after{display:block; clear:both; content:'';}
.btn_bottom.con_btn:after{display:none;}
.btn_style_yes, .btn_style_yes2, .btn_style_yes3{box-sizing: border-box;width:50%;height: 54px;line-height: 54px;background-color: var( --main-color-);color:#000;text-align:center;font-size:15px;display:block;font-weight:normal; font-weight:600;}
.btn_style_premium, .btn_style_premium2 {box-sizing: border-box;width:50%;height: 54px;line-height: 54px;background-color:#d63f3c;color:#fff;text-align:center;font-size:15px;display:block;font-weight:normal; font-weight:600}
.btn_style_yes3, .btn_style_premium  {border-radius:10px;margin-right:5px;}

.btn_style_no{box-sizing: border-box;width: 50%;height: 54px;line-height: 54px;background-color: #454E62;color:#fff;text-align:center;font-size:15px;display:block;font-weight:normal; border:0px solid #76c1e5;font-weight:600}
.btn_style_brown{box-sizing: border-box;width: 50%;height: 54px;line-height: 54px;background-color: #96602E;color:#fff;text-align:center;font-size:15px;display:block;font-weight:normal; border:0px solid #76c1e5;font-weight:600;}
.btn_style_dark{box-sizing: border-box;width: 50%;height: 54px;line-height: 54px;background-color: #454E62;color:#fff;text-align:center;font-size:15px;display:block;font-weight:normal; border:0px solid #76c1e5;font-weight:600;border-radius:10px;}
.btn_style_gray{box-sizing: border-box;width: 50%;height: 54px;line-height: 54px;background-color: #ddd;color:#000;text-align:center;font-size:15px;display:block;font-weight:normal; border:0px solid #76c1e5;font-weight:600}
.btn_style_gray2{box-sizing: border-box;width: 50%;height: 54px;line-height: 54px;background-color: #F4F4F5;color:#000;text-align:center;font-size:15px;display:block;font-weight:normal; border:1px solid #bbb;font-weight:600;border-radius:10px;}
.btn_style_white{box-sizing: border-box;width: 50%;height: 54px;line-height: 54px;background-color: #fff;color:#222;text-align:center;font-size:15px;display:block;font-weight:normal; border:1px solid #222;font-weight:600;border-radius:10px;}
.btn_style_mint{box-sizing: border-box;width: 50%;height: 54px;line-height: 54px;background-color: #6cf6ff;color:#222;text-align:center;font-size:15px;display:block;font-weight:normal; border:0px solid #76c1e5;font-weight:600}
.btn_bottom .st02{background:#fafafa;border:1px solid #e2e2e2;color:#555555;height:40px; line-height:40px;}
.btn_bottom .st02:first-child{margin-right:2%;}
.btn_bottom.one_btn{text-align:center; z-index: 9;}
.btn_bottom.one_btn .btn_style_yes{width:100%; margin-right:0;margin: 0 auto;float:none;text-align:center !important;} /* 하나짜리버튼 */
.btn_bottom.one_btn .btn_style_no{width:100%; margin-right:0;margin: 0 auto;float:none;text-align:center !important;} /* 하나짜리버튼 */
.btn_bottom.one_btn .btn_style_brown{width:100%; margin-right:0;margin: 0 auto;float:none;text-align:center !important;} /* 하나짜리버튼 */

/* 버튼 세개 */
.btn_bottom.three_btn {display:flex; justify-content: space-between; z-index: 10;}
.btn_bottom.three_btn button, .btn_bottom .three_btn a{height: 50px;line-height: 50px;/* margin-right:1%; */}
.btn_bottom.three_btn button:last-child, .btn_bottom .three_btn a:last-child{margin-right:0;}

.btn_bottom.three_btn .btn_style_no, .btn_bottom.three_btn .btn_style_brown{width:25%}
.btn_bottom.three_btn .btn_style_yes{width:50%}

/* 콘텐츠 내에 있는 버튼들 */
.btn_bottom.three_btn.con_btn {display:flex;position: static;left: 0;transform: translateX(00%);}
.btn_bottom.three_btn.con_btn button, .btn_bottom.three_btn.con_btn a{height: 50px;line-height: 50px;margin-right:1%;border-radius:10px;border-radius:10px;width:32%;font-weight:normal;}
.btn_bottom.three_btn.con_btn button:last-child, .btn_bottom.three_btn.con_btn a:last-child{margin-right:0;}

.btn_bottom.con_btn {display:flex;position: relative;left: 0;transform: translateX(00%);}

.btn_bottom.wid4_6{position:relative;justify-content: space-between;}
.btn_bottom.wid4_6 .w68 {border-radius:10px;font-weight:normal;}
.btn_bottom.wid4_6 .w28 {border-radius:10px;font-weight:normal;}



.layer-pop.layer_st_vote.btn_bottom .btn_style_yes {width:92%}


.mt03 {margin-top: 3px;}
.mt10{margin-top:10px;}
.pl10{padding-left:20px;}
.pl20{padding-left:20px;}
.pl30{padding-left:30px;}
select.fz14{font-size: 14px;max-width: 120px;}
.w28{width:28% !important}
.w68{width:68% !important}
.w50p{width:50px !important}
.w100p{width:100px !important}
.h80{height:80% !important}
/* {box-sizing: border-box;width:50%;float: left;height: 60px;line-height: 60px;background-color: #222d41;color:#fff;text-align:center;font-size:16px;display:block;font-weight:bold;} */
.btn_style02_no{box-sizing: border-box;width: 50%;float: left;height: 60px;line-height: 60px;background-color: #76c1e5;color:#fff;text-align:center;font-size:16px;display:block;font-weight:bold; border:1px solid #76c1e5;}
.del_btn01{background-color:#f5f5f5;border-radius:40px;height:32px;line-height:32px;text-align:center;margin:15px 0;}
.del_btn01 button{color:#999; font-size:13px;background:none;display:block;height:inherit;line-height:inherit;text-align:center;width:100%;}

.w40{width:40%;}
.w50{width:50%;}
/* 공통사용 */
.line01{width:100%; background:#f5f5f5; height:8px; margin: 30px 0 30px}
.line02{width:100%; background:#ddd; height:1px; margin: 13px 0 13px}
.line03{width:100%; background:#f8f8f8; height:4px; margin: 7px 0px}
.pd_wrap{padding-top:50px;padding-bottom:20px;}
.pd_wrap01{padding-top:57px;padding-bottom:60px;} /*헤더 높이값만빼고, 아래쪽에 버튼 하나*/
.pd_sub_wrap{padding-top:57px;padding-bottom:80px;} /*headertop(서브제목) + 아래에 page메뉴*/
.pd_btm100{padding-top:0px;padding-bottom:80px;} /*0) + 아래에 page메뉴*/
.pd_wrap02{padding-top:0px;padding-bottom:40px;}
.pd_wrap02_bottom{padding-top:0px;padding-bottom:0px;}
.pd_wrap03{padding-top:20px;padding-bottom:30px;}
.pd_wrap04{padding-top:20px;padding-bottom:70px;} /* 위에 쪼금 띄우고 아래에 버튼 하나 */
.main_pd_wrap{padding-top:0px;padding-bottom:100px;} /* 위쪽에 검색버튼, 아래에 page메뉴 */

.tit01, .tit01.st02 {font-size: 1.6em;}
.tit01 {color: #000;font-weight: 400;font-weight: 800;letter-spacing: -.05em;padding-bottom: 10px;position: relative;}
.tit01 .go_more{position:absolute;right:10px;font-size:0.85em;color:#999;font-weight:normal;}
.tit01 .go_more img{margin-left:4px;}
.tit01 .red_txt{color:#EB2B2B;font-size:0.7em;font-weight:normal;}
.tit01 .red_txt .gray_bar{display: inline-block; height: 13px; margin: 0 10px;width:1px; background:#eee;    vertical-align: -3px;}
.tit01 .pp_txt{color:var(--main-color-);font-weight:400;}
.tit01 .go_right{position:absolute;right:0px;font-size:0.85em;color:#999;font-weight:normal;top: 50%;transform: translateY(-50%);}
.tit01 .go_right img{margin-right:0}
.tit02 .go_right{position:absolute;right:10px;font-size:0.85em;color:#999;font-weight:normal;top: 50%;transform: translateY(-50%);}
.tit02 .go_right img{margin-right:0}
.tit02 {font-size: 1.45em;font-weight: 600;margin: 0px 0 5px;color:#333;position: relative;padding-top: 5px; padding-bottom: 4px;letter-spacing: -0.005em;}
.tit02 .red_txt{color:#EB2B2B;font-size:0.7em;font-weight:normal;}
.tit02 .red_txt .gray_bar{display: inline-block; height: 13px; margin: 0 10px;width:1px; background:#eee;    vertical-align: -3px;}
/* .tit02 .go_btn{position:absolute;right:10px;font-size:0.85em;color:#999;font-weight:normal;top: 50%; transform: translateY(-50%);}
.tit02 .go_btn.go_more a{font-size:14px;color:#999} */
.tit02 img{margin-right:7px;}

.btn_title {vertical-align:middle;display: inline-block;width:calc(100% - 90px);}

.tit02 .txt_s_gray{font-size:0.9em;color:#999;font-weight:normal;}
.tit03 {font-size: 1.3em;font-weight: bold;padding:15px 0;color:#000;position: relative;letter-spacing: -0.05em;position:relative;padding-right:20px;}
.tit03 img{margin-right:10px;}
.tit03 .gray_txt{color:#999999;font-size:0.8em;font-weight:normal;}
.tit03 .gray_txt .gray_bar{display: inline-block; height: 13px; margin: 0 10px;width:1px; background:#eee;vertical-align: -3px;}
.tit03 .go_right{position:absolute;right:10px;font-size:0.85em;color:#999;font-weight:normal;top: 50%;transform: translateY(-50%);z-index:3}
.tit03 .go_right img{margin-right:0}
.tit04{margin: 15px 0 10px;color:#000;font-size:1.125em;}
/* 점 있는 애들 */
.tit06 {font-size: 1.2em;font-weight: bold;margin: 13px 0 7px;color:#000;background: url('../images/bullet2.png') no-repeat left;padding-left:15px;}
.tit07 {font-size: 1em;font-weight: normal;margin: 13px 0 7px;color:#000;background: url('../images/bullet.png') no-repeat left;padding-left:10px;}
.tit07_1{font-size:1.1em; font-weight:bold;margin: 13px 0 8px;color:#000;background: url('../images/bullet.png') no-repeat left;padding-left:10px;text-align:left}
.tit07_1 .gray_txt{color:#999999;font-size:0.8em;font-weight:normal;}
.tit07_1 .gray_txt .gray_bar{display: inline-block; height: 13px; margin: 0 10px;width:1px; background:#eee; vertical-align: -3px;}
.tit07_2{font-size:1em; font-weight:normal;margin: 13px 0 7px;color:#000;}
.tit07_3 {font-size: 1.2em;font-weight: bold;margin: 13px 0 7px;color:#000;background: url('../images/bullet.png') no-repeat left;padding-left:15px;}
.star_red{color:#E51D1D;font-weight:normal; font-size:12px;}
.star_black{color:#000000;font-weight:normal; font-size:12px;}

.txt_s_red{color:#E51D1D;font-size:0.9em;margin-bottom:20px;margin-top:5px;padding:0 10px;}
.txt_gray{font-size:0.9em;color:#999;font-weight:normal;margin-top:5px;}
.txt_dark_gray{font-size:0.9em;color:#262626;font-weight:normal;margin-top:5px;}
.txt_gray_s{font-size:0.85em;color:#999;font-weight:normal;margin-top:5px;}
.txt_s_red img{margin-right:5px;}
.tit02 .ess{font-size:0.8em;}
.bgc_gray{background:#eee}


/* 공통 체크표시 */
.terms_chks{text-align:left;font-size:14px; color:#000; padding:0px 0 0px; position:relative;display:block;width:auto;margin-bottom:6px;}
.terms_chks .chk1+i{top:0px;}
.terms_chks .chk1:checked+i:after{vertical-align:0px!Important;}

.terms_chks .chk1{width:10px; height:10px; margin-right:5px; vertical-align:middle;display:none;}
.terms_chks .chk1+i{display:inline-block; width:32px; height:32px; position:absolute; left:0; top:0px;border:0;border-radius:0;box-sizing:border-box;background:url(../images/chk1.svg) no-repeat left 3px center;background-size:20px;}
.terms_chks .chk1+i{pointer-events:none;}
.terms_chks .chk1:checked+i{ text-align:center;}
.terms_chks .chk1:checked+i:after{content:''; display:inline-block; background:url(../images/chk1-on.svg) no-repeat left 3px center;width:32px; height:32px;border:0;box-sizing:border-box;position:absolute;top:0px;left:0px;background-size:20px;}
.terms_chks label { display:inline-block;position:relative;left:0;padding-left:30px;line-height:30px;margin-left:0;cursor:pointer;color:#000;font-weight:400;vertical-align:top;}
.terms_chks a { float:none;display:block;color:#666;font-size:14px;border-bottom:0;margin-right:0;margin:0px;border:1px solid #666666;background:#fff;border-radius:3px;padding: 3px 10px}
.terms_chks label span { color:#ff6666;}

/* 성별 선택에서 사용 (join) */
.terms_chk33{display:inline-block;color:#222; position:relative;width:48%;vertical-align:top;font-size: 13px;height: 45px;}
.terms_chk33 .chk1+i{top:0px;}
.terms_chk33 .chk1:checked+i:after{vertical-align:0px!Important;}
.terms_chk33 .chk1{width:10px; height:10px;vertical-align:middle;display:none;}
.terms_chk33 .chk1+i{display:inline-block; width:100%; height:55px;box-sizing:border-box}
.terms_chk33 .chk1+i{pointer-events:none;}
.terms_chk33 .chk1:checked+i{ text-align:center;}
.terms_chk33 label { background:var(--sub-color2-);color:#222;display:inline-block;position:relative;width:100%;
height:42px;position:absolute;top:0;left:0;line-height:42px;border-radius:5px;text-align: center;z-index:2;}
.terms_chk33  label  p { display:inline-block;float:right;margin-right:15px;}
.terms_chk33 .chk1:checked+i+label {background-color:var(--main-color-);color:#222;font-weight:bold;}

/* 이용권 구매에서 사용 */
/* .terms_chk33_1{display:inline-block;color:#222; position:relative;width:100%;vertical-align:top;font-size: 13px;height: 100%;background:var(--main-color-);border-radius:10px;}
.terms_chk33_1 .chk1+i{top:0px;}
.terms_chk33_1 .chk1:checked+i:after{vertical-align:0px!Important;}
.terms_chk33_1 .chk1{width:10px; height:10px;vertical-align:middle;display:none;}
.terms_chk33_1 .chk1+i{display:inline-block; width:100%; height:55px;box-sizing:border-box;height:100%;}
.terms_chk33_1 .chk1+i{pointer-events:none;}
.terms_chk33_1 .chk1:checked+i{ text-align:center;}
.terms_chk33_1 label { background:var(--sub-color-);color:#222;display:inline-block;position:relative;width:100%;
;position:absolute;top:0;left:0;line-height:42px;border-radius:5px;text-align: center;z-index:2;}
.terms_chk33_1  label  p { display:inline-block;float:right;margin-right:15px;}
.terms_chk33_1 .chk1:checked+i+label {color:#222;font-weight:bold;} */

/* 매물등록 클릭 */
.terms_chk34{display:inline-block;color:#333333; position:relative;width:48%;vertical-align:top;font-size: 13px;height: 45px;}
.terms_chk34 .chk1+i{top:0px;}
.terms_chk34 .chk1:checked+i:after{vertical-align:0px!Important;}
.terms_chk34 .chk1{width:10px; height:10px;vertical-align:middle;display:none;}
.terms_chk34 .chk1+i{display:inline-block; width:100%; height:55px;box-sizing:border-box}
.terms_chk34 .chk1+i{pointer-events:none;}
.terms_chk34 .chk1:checked+i{ text-align:center;}
.terms_chk34 label { background:#eee;color:#222;display:inline-block;position:relative;width:100%;height:42px;position:absolute;top:0;left:0;line-height:42px;border-radius:5px;text-align: center;z-index:2;}
.terms_chk34  label  p { display:inline-block;margin-right:15px;}
.terms_chk34 .chk1:checked+i+label {background-color:var(--main-color-);color:#222;font-weight:bold;}

/* 라디오버튼 */
.terms_chk35{text-align:left;font-size:14px; color:#000; padding:0px 0 0px; position:relative;display:block;width:auto;margin-bottom:6px;}
.terms_chk35 .chk1+i{top:0px;}
.terms_chk35 .chk1:checked+i:after{vertical-align:0px!Important;}
.terms_chk35 .chk1{width:10px; height:10px; margin-right:5px; vertical-align:middle;display:none;}
.terms_chk35 .chk1+i{display:inline-block; width:32px; height:32px; position:absolute; left:0; top:0px;border:0;border-radius:0;box-sizing:border-box;background:url(../images/chk3.svg) no-repeat left 3px center;background-size:20px;}
.terms_chk35 .chk1+i{pointer-events:none;}
.terms_chk35 .chk1:checked+i{ text-align:center;}
.terms_chk35 .chk1:checked+i:after{content:''; display:inline-block; background:url(../images/chk3-on.svg) no-repeat left 3px center;width:32px; height:32px;border:0;box-sizing:border-box;position:absolute;top:0px;left:0px;background-size:20px;}
.terms_chk35 label { display:inline-block;position:relative;left:0;padding-left:30px;line-height:30px;margin-left:0;cursor:pointer;color:#000;font-weight:400;vertical-align:top;}
.terms_chk35 a { float:none;display:block;color:#666;font-size:14px;border-bottom:0;margin-right:0;margin:0px;border:1px solid #666666;background:#fff;border-radius:3px;padding: 3px 10px}
.terms_chk35 label span { color:#ff6666;}



.table_st01 table tr td,.table_st01 table tr th {padding:10px;text-align:center;padding:12px 3px}
.table_st01 table tr th{border-bottom:1px solid #000;white-space:nowrap;}
.table_st01 table tr{border-bottom: 1px solid var(--border-color-)}

.table_st02{font-size:14px;}
.table_st02 table tr th, .table_st02 table tr td{padding:12px 3px;text-align:left;color:#797979;font-weight:400;padding-left:20px;}
.table_st02 table tr {border-bottom:1px solid var(--border-color-);}
.table_st02 table tr:first-child{border-top:1px solid var(--border-color-)}
.table_st02 table tr td{color:#222}
.btn_normal a{height: 43px; line-height: 43px;text-align: center;display: block;background-color:#369bff;border-radius:5px;color:#fff;}

.btn_style_yes.btn_mini_center{float:none; margin: 0 auto;}


/*페이징*/
#paging{text-align:center; clear:both; margin:20px 0;}
#paging a{height:22px;  line-height:22px;min-width:10px; box-sizing:border-box;padding:0 8px; font-weight:300;text-align:center; display:inline-block; font-size:0.85em; color:#ccc; margin:0 0 0 1px;border-radius:5px}
#paging img{vertical-align:-2px;}
#paging a.active{background:var(--main-color-);color:#fff;}
#paging a.btn_prev,
#paging a.btn_next{background:#fff; border-color:#fff;margin:0 10px;vertical-align: 1px;}


/* 개인정보처리방침+이용약관 */
.agree_wrap{white-space: pre-line;}



/* -------------------------------------------------------------------- 2022-09-14 시작 */
/*공통*/
/* 그림자 */
.b_shadow{box-shadow: 0 7px 15px -6px #ddd}

/* 상단검색 */
.search_top_wrap{background:#fff;padding:20px 0;}
.search_top{display: flex;align-items: center;padding: 10px 0;}
.btn_map_setting{background-color: #65401E;color:#fff; font-weight: bold;width:120px;height:50px; line-height: 50px;border-radius: 5px;text-align: center;margin-right:5px;}
.btn_map_setting img{margin-right:5px;}
.btn_map_setting.filter01_open_btn {cursor:pointer;}
.searchbox{background:#fff; width:calc(100% - 130px);}
.searchbox .t-txt{text-align:center; margin-bottom:10px; font-size:16px;}
.searchbox fieldset{height:50px; border: 2px solid #707070; border-radius:5px; position:relative;box-sizing: border-box;}
.searchbox fieldset .input{width:85%; height:38px; background:none; border:none;line-height: auto;margin-top: 0;}
.searchbox fieldset .input:focus{border:none;    outline-style: none;}
.searchbox fieldset .btn{width:38px; height:38px; position:absolute; right:0px; top:0; background: url(../images/icon_search.svg) center top 10px no-repeat; background-size: auto; border:none; font-size:0;min-width:38px;}
.searchbox fieldset .btn2{width:30px; height:30px; position:absolute; right:29px; top:0; background: url(../images/icon_close.svg) center top 10px no-repeat; background-size: auto; border:none; font-size:0;min-width:30px;}
.searchbox fieldset .btn3{width:38px; height:38px; position:absolute; right:0px; top:0; background: url(../images/icon_close.svg) center top 10px no-repeat; background-size: auto; border:none; font-size:0;min-width:38px;z-index:1;}

.filter02_open_btn.go_right {background-color: #65401e;border-radius: 5px;padding: 0 18px;right: 3px;}
.filter02_open_btn > span {color: #e8e3dd;font-weight: 300;font-size: .8em;}
.filter02_open_btn > img {margin: 5px;}
      

.sel_local {display: flex;margin-top: 5px;flex-wrap: wrap;align-items: center; overflow: scroll hidden;}
.sel_local p{color:#999;font-size: 0.9em;width:70px;}
.sel_local ul {display: flex;}
.sel_local ul li{padding:3px 7px; margin: 0 3px 3px 0;border-radius: 20px; background-color: #EFEFEF;color:#444;font-size:0.85em;font-weight: 500;min-width:max-content;}
.sel_local ul li img.close_btn{width:13px;margin-top:-2px;cursor:pointer;}


/* 메인 */
/* .main_wrap{padding-bottom:110px;} */

.main_dunggi_wrap{ position:relative; }
.filter_ctg li:last-child{ padding-right: 30px; }
.main_dunggi_wrap div.grad_arrow{ 
  width: 6%;
  text-align: center;
  position: absolute;
  right: 0;
  bottom: 0px;
  height: 55px;
  line-height: 50px;
  background: #fff;
  box-shadow: -10px 0 10px #fff;
}

.main_box .swiper-slide .img_wrap img{width:100%; height:100%; object-fit:cover;vertical-align:middle;}

/* 프리미엄 */
.main_premium_wrap{margin-top:0px;padding-right:0;width:100%;background:#fff;}
.main_premium_wrap .swiper-pagination{top:10px;right:0;height:20px;}
.main_box{padding:15px 5% 20px 5%;border-radius:0 0 100px 0;background-color:#ffde00;}
.main_box .swiper-pagination-bullet-active{background:#444444}
.main_box01 {border-radius: 0 0 100px 0;}
/* .main_box .swiper-container{padding-top:30px;} */
/* .main_box .c_hash ul li.c_option span{background-color: #C7B21D;border:1px solid #C7B21D;color:#fff;} */
.main_box01 .img_slide_wrap {width: 100%;overflow-x: scroll;overflow-y: hidden;display: flex;}
.main_box01 .img_wrap {width:auto;min-width: max-content;width: 20%;margin-right:20px;}


.swiper-slide .img_wrap img{border-radius:20px;}
.c_wrap {width: 100%;height:100%;overflow: hidden;}
.c_wrap .img_wrap{width: 100%;height:auto;}
.c_wrap .img_wrap img {border-radius: 20px;height: 100%;width: 100%;object-fit: cover;vertical-align: middle;}
.c_wrap .img_wrap {height: 180px;}

.e_wrap .img_wrap{width: 100%;height:auto;}
.e_wrap .img_wrap img{max-width:100%;max-height:100%;vertical-align: middle;object-fit:cover;border-radius:20px;}

.c_wrap .info01 {position: relative;margin-bottom: 10px;margin-top: 20px;}
.c_wrap .info01 .c_dong{display: inline-block;}
.c_wrap .info01 .c_price{display: inline-block;font-weight: bold;margin-left:5px;}
.c_wrap .info01 .c_dong span{padding: 3px 7px; text-align: center;color:#fff;border-radius:5px;background-color: #96602E;font-size:0.9em;}
.c_wrap .info01 .wish_chk {position: absolute;top:-2px;right:5px;z-index:2}
.c_wrap .c_type{display:inline-block;padding: 3px 6px;text-align: center;border-radius: 5px;color: #fff;font-size: 0.9em;background-color: #fff;border: 1px solid #ccc;color: #65401E;}
.c_wrap .c_desc {color:#666666;font-size:0.9em;}
.c_wrap .c_desc .urgent{display:inline-block;width:40px;height:18px;padding:0px 10px 4px 10px;;text-align: center;border-radius: 5px;background-color: #fff;background:#e05e66;color: #fff;font-weight:300;margin-right:2px;}
.list_con .budong_tag{position:absolute; left:0;top:0;z-index: 2;}
.wish_chk{z-index:2}

.prim_chk{position: absolute;top:-2px;right:5px;z-index:4}

.c_hash ul{display: flex;flex-wrap: wrap;align-items: center;margin-bottom:5px}
.c_hash ul li span{padding: 2px 5px; text-align: center;border-radius: 5px;color:#fff;font-size:0.9em;}
.c_hash ul li {margin-right: 3px;margin-bottom:6px;}
/* .c_hash ul li.c_type span{background-color: #454E62;} */
.c_hash ul li.c_option span{background-color: #fff;border:1px solid #ccc;color:#65401E;}
/* .c_hash ul li.c_option.bgc_brown span{background-color: #C7C9CF;} */

/* 리스트 */
.list_type01{background-color: var(--sub-color-);}
.list_type01 .list_con{margin-bottom: 5px;box-shadow: 1px 4px 9px 0px #ddd;background-color: #fff; padding: 20px;position:relative}
.list_type01 .list_con.more {text-align:center;font-size:1.2em;}
.near_title {display:flex;justify-content:space-between;padding:5px 10px;}
/* 퀵메뉴 */
.layer-pop.quick_pop{background:none;text-align: left;min-height:auto;}
.quick_menu ul li{padding: 20px 0; border-bottom: 1px solid rgba(255, 255, 255, .5);}
.quick_menu ul li:last-child{border:none;}
.quick_menu ul li a{font-weight: bold; font-size: 1.1em;display: block;color:#fff;}
.quick_menu ul li a img{margin-right: 5px;}
.quick_close_btn{width:80%;position: fixed;left:12%; bottom:50px;color:#fff;z-index: 11;display:none;max-width:800px}

/* 필터1팝업 */
.filter_type01_wrap{background:#fff}
.filter_type01_wrap .filter_open_btn img{margin-right: 0;}
.main_dungji_wrap{margin-top: 0px;}
.filter_opt01{display: flex;align-items: center;}
.filter_opt01 li{width:120px;font-size: 1.1em;margin-right:3px}
.filter_opt01 li:last-child{text-align: right;width:calc(100% - 130px);}
.filter_opt01 li select{max-width:120px;margin-bottom:6px;padding:auto 10px;}
.filter_ctg {display: flex;height: 50px; line-height: 50px;}
.filter_ctg_wrap{width:100%; background-color: var(--sub-color-); position:relative;}
/* .filter_type01_wrap .terms_chks .chk1+i{background-position: left center;}
.filter_type01_wrap .terms_chks .chk1:checked+i:after{background-position: left center} */
.filter_ctg {width:86%;overflow-x:scroll;overflow-y:hidden;margin-left:7%;padding-bottom:5px;}
.filter_ctg_wrap .filter-right-move{margin:15px 3px;height:inherit;width:21px;height:20px;position:absolute; right:0px;top:-5px;}
.filter_ctg_wrap .filter-left-init{margin:15px 5px;height:inherit;width:6%;height:20px;position:absolute; left:0px;top:0px;}

.filter_ctg li{padding:0 5px; height:inherit;min-width: max-content;width: 20%;}
.filter_ctg li.reload{padding: 0 15px;}
.filter_ctg li button{color:#222;height:48px;font-size:1.125em;font-weight:bold;}
.filter_ctg li.active button{border-bottom: 5px solid var(--main-color-);box-sizing: border-box;}

.filter_pop01 .lyr_top{padding: 10px 10px;border-bottom: 1px solid #E9E9E9;}
.filter_pop01 .lyr_top .sel_wrap{display: flex;justify-content: space-between;}
.filter_pop01 .lyr_top .sel_wrap select{border: 1px solid #e9e9e9;width:48%;max-width:350px;}

/* .filter_pop01 .pop_head{box-shadow:none;} */

/* 필터2팝업 (공통사용가능)*/
/* .full_pop .pop_head {box-shadow: 1px 1px 7px 3px #eee;} */
.pop_head {box-shadow: 0px 3px 2px 0px #eee; text-align: center;}
.pop_head {z-index:2;height:40px;line-height:40px;padding:10px 0 10px;width:100%;background:#fff}
.pop_head .top_close_btn{position: absolute;right:4%; top:10px;}
.pop_subtit .bullet_wrap{margin-right:5px;}
.dan4 {display: flex;flex-wrap: wrap;}
.dan4 li{width: 24%;margin-right: 1%;margin-bottom:3px;font-size: small;}
.dan4 li:last-child{margin-right: 0;}


.dan2_danwe{display:flex;align-items: center;}
.dan2.aitem_center{align-items: center;}


.dan_short{display: flex; width:calc(100% - 140px); padding-top:10px;}
.dan_short li{margin-right:15px;}

.dan_short2{display: flex; width:100%; padding-top:10px;}
.dan_short2 li{margin-right:15px;}

.filter_pop02.layer-pop .ipt_box{padding-top:0; margin-top:0;border:none;}
.filter_pop02 .ft_I_all_wrap{display:inline-block;width:100px;position: absolute;top: 0px;left:50px;}
.filter_pop02.layer-pop.full_pop .layer_inner{height: calc(100vh - 170px);}

/* 
.dan_short.linenext{display: flex;flex-wrap: wrap;}
 */
.dan5{display: flex;justify-content: space-between;flex-wrap: wrap;justify-content: flex-start;}
.dan5 li{width:19%;margin-right:1.25%;margin-bottom:3px;}
.dan5 li:last-child{margin-right: 0;}
.dan5 .terms_chk33 label{background:#ddd}

.con .con_box {margin-top:25px;}

.right_txt{position: absolute;right:0;top:2px;color:#999;font-weight: 400;font-size: 0.8em;}

.mini_bar{margin:0 2px;}

.min-max-input{width:120px;}



/* 키워드팝업 */
.search_popup .searchbox {border-bottom: 1px solid #E9E9E9;padding-bottom:15px; margin-bottom:15px;}
.search_popup .searchbox fieldset{text-align:left;}
.keyword_wrap ul li {position: relative;padding: 14px 0;border-bottom: 1px solid #E9E9E9;text-align:left;}
.keyword_wrap ul li a{display: block;width: calc(100% - 100px);padding-left:20px;}
.keyword_wrap ul li .del_btn{position: absolute;right:0;top:9px}
.search_popup .searchbox{width:100%;margin:10px 0;}


/* 상세보기 */
/* .view_wrap{position: relative;z-index: 3;} */
.view_wrap .top_info_wrap{padding: 15px 0;background-color: #fff;}
.photo_slide{max-height: 320px;height: 290px;}
.photo_slide .swiper-wrapper img{width: 100%;}
.photo_slide .water_m{position:absolute; left:50%;margin-left:-80px;transform: translateY(-50%);top:50%;text-align:center;z-index:2}
.photo_slide .water_m img{width:auto;}
/* 	이미지팝업오픈 */
#pop_img {width:100%}
.layer-pop.full_pop.big_img_pop {width:90%;height:90%;left:5%}
@media only screen and (min-width: 800px){
.layer-pop.full_pop.big_img_pop {
    left: 50%;
    height: 90%;
}
}

.big_img_pop .img_wrap{position:relative;margin-top:60px;}
.big_img_pop .img_wrap .water_m{position:absolute; left:50%;margin-left:-80px;transform: translateY(-50%);top:50%;text-align:center;z-index:2}
.c_wrap02 .c_dong {display: inline-block;}
.c_wrap02 .b_num {display: inline-block;margin-left: 5px}
.c_wrap02 .c_dong span {padding: 3px 7px;text-align: center;color: #fff;border-radius: 5px;background-color: #96602E;font-size: 0.9em;}
.c_wrap02 .c_rent_type{position: relative;font-size: 1.4em;margin: 14px 0 7px; font-weight: bold;}
.c_wrap02 .c_rent_type .price{letter-spacing:-1px;display: inline-block ;margin-left: 5px;}
.c_wrap02 .date_wrap > div{font-size: 0.9em;color:#999;}
.c_wrap02 .date_wrap > div span{font-size: 0.9em;color:#999;}
.c_wrap02 .date_wrap > div.hit{position: absolute; right: 20px;}
.c_wrap02 .right_wrap {position: absolute;top: -2px;right: 0px;display: flex;flex-wrap: wrap;}
.view_wrap .c_wrap02 .right_wrap{top:-2px}
.c_wrap02 .right_wrap > div{padding: 0;margin: 0;}
.date_wrap {display: flex; align-items: center;}
.date_wrap .gray_bar {display: inline-block;height: 13px;margin: 0 10px;width: 1px;background: #eee;vertical-align: -3px;}

.view_wrap .mid_info_wrap {margin-top: 10px;background-color: #fff;}
.view_wrap .mid_info_wrap .info01 .dan4 {padding: 20px 0 15px;border-bottom: 1px solid #F4F4F5;margin-bottom: 20px;background:rgba(255,222,0,.2)}
.view_wrap .mid_info_wrap .info01 .dan4 li{text-align: center;}
.view_wrap .mid_info_wrap .info01 .dan4 li p{margin-top: 5px;}
.view_wrap .mid_info_wrap .info01 .txt{font-size: 0.9em; color:#666;}

.mid_info_wrap .pop_subtit {font-weight: bold; font-size: 1.125em;text-align: left;margin: 10px 0;position:relative;margin-top:30px;margin-bottom: 10px;}
.mid_info_wrap .pop_subtit .gray_txt{font-size: 0.7em; font-weight: normal;color:#666;}

.table_st03 table tr td,.table_st03 table tr th {padding:10px;text-align:left;padding:12px 3px;font-size:13px;font-weight:normal;}
.table_st03 table tr th {color:#999;}
.table_st03 table tr td {color: #000;}
.table_st03 table tr {border-bottom: 1px solid var(--border-color-)} 
.table_st03 .red_txt {color:#EB2B2B;}

.view_wrap .info02 > div{font-size: 1em;}

.info02 .doro{color:#666;}
.info02 .doro .bt{background-color:var(--sub-color-);color:#999;padding:2px 5px;border-radius: 5px;margin-right: 5px; font-size: 0.8em;}

.info03 .con_box{padding:10px;border-top: 1px solid var(--border-color-);border-bottom: 1px solid var(--border-color-);margin-top: 5px;margin-bottom: 20px;line-height: 1.8;font-size:0.95em;}
.view_wrap .tit07_1{margin-top:25px;}
.view_wrap .info02 > div{font-size:1.1em;}
.view_wrap .c_hash2{margin-bottom:0;}
.view_wrap .c_hash2 li{padding: 2px 7px;text-align: center;border-radius: 5px;background-color: #fff;font-size: 0.9em;border:1px solid #ccc}
/* .view_wrap .c_hash2 .dot{margin: 0 3px;} */

.info04:first-child{border-top:1px solid #fff;}
.info04 > a {display:block;}
.info04 {padding:0px 0 20px;position:relative;}
.info04_inner {display:flex;justify-content: flex-start;padding:20px 0 10px;border-top:1px solid var(--border-color-)}
.info04 .img_wrap {padding:0 10px;max-height:100px;text-align:center}
.info04 .img_wrap img {object-fit: cover;height: 100%;width: 100%;border-radius: 10px;}
.info04 .box {padding:0px 15px 0px 0px;}
.info04 .box a {vertical-align: 1px;}
.info04 .box .adrs {color: #999;font-size: 0.85em;margin-top: 10px;font-weight: normal;}
.info04 .box .c_name {font-weight:bold; font-size:1.2em;margin-bottom:5px;}
.info04 .box .c_name a {display:inline-block;}
.info04 .box .c_name img {margin-top:-10px; margin-left:5px}
.info04 .box a > div {color:#000;}
.info04 .box a > div img {margin-right:5px;color:#000}
.info04 .fnc_wrap {position: absolute;right:0px; top:60px;z-index: 2;display:flex;}

.extent_txt {position:relative}
.extent_txt .txt01 {display: block;}
.extent_txt .txt02 {display: none;}
.chng_btn {position: absolute;right:0;top:0px;}

/* 내 글 일 때 버튼영역 */
.btn_bottom.in_my_write a {width:25%}
.btn_bottom.in_my_write .btn_style_yes {width:40%}
.btn_bottom.in_my_write .btn_style_premium {width:40%}
.btn_bottom.in_my_write .btn_style_yes2 {width:50%}

.btn_bottom.in_my_write2 a {width:50%}
/* ★★★★★★★★★★★★★★ member ★★★★★★★★★★★★ */
/* 회원가입 */

/* 일반회원+공통 */
.pp_top_text_tp01{ padding:25px 0;font-size:1.1em;background: #fff; text-align:Center}
.agree_area.service{background-color:#eee; border-radius:5px;padding:10px;}
.agree_area .term_pop_btn, .agree_area .privacy_pop_btn, .agree_area .marketing_pop_btn{position:absolute;right:0px;top:0px;color:#797979;font-size:0.7em;}
.agree_area .terms_chk:after{display:block; clear:both;content:" "}
.all_agr.terms_chk32 label{padding-left: 8%;}
.all_agr.terms_chk32 .chk1+i {left: 5%;}
.join_wrap .chk_st01{margin-top:10px;}
.dan2{display: flex;justify-content: space-between;}
.dan2 .terms_chks{width:48%;max-width:350px;}
.ph_num_dan3{display: flex;align-items: center;position: relative;}
.ph_num_dan3 .mid_bar{display:inline-block;margin:0 5px;}
.ph_num_dan3 input[type=text] {width:calc((100% - 140px)/3);text-align:center;}
.ph_num_dan3 input[type=number] {width:calc((100% - 140px)/3);text-align:center;}
.cpn02_join_wrap{display: none;}
.join_wrap .terms_chk33:first-child{margin-right:2%;}
.txt_s_gray{font-size:0.9em;color:#949a9f;padding-left:10px;}
.all_agr{font-size:1.1em;padding-left: 4px;margin-bottom:15px}
.all_agr label{font-weight:600;}
.has_btn{margin-bottom:2px;margin-top:2px;position:relative}
.has_btn.form_Address{margin-bottom:3px;}
.form_wrap{margin-bottom:10px;padding-bottom:10px;}
.form_wrap2 {margin-bottom:10px;padding-bottom:10px;display:flex;flex-direction:row;justify-content: space-between;}
.form_wrap2 .one-line {width:calc(100% - 120px);}
.form_wrap2 .one-line2 {width:calc(100% - 160px);}
.form_wrap2 .one-line .one-line-won {width:calc(100% - 35px);text-align:right;padding-right:10px}
.form_wrap2 .one-line .one-line-num {width:calc(100% - 20px);text-align:right;padding-right:10px}
.form_wrap2 .one-line .one-line-kw {width:calc(100% - 30px);text-align:right;padding-right:10px}
.form_wrap2 .one-line .danwe_txt {width:35px;color:#999;margin-left: 5px;text-align: right;} /*만원*/
.form_wrap2 .one-line .danwe_txt2 {width:20px;color:#999;margin-left: 3px;text-align: right;} /*개, 평 (한글자)*/

.form_wrap2 .one-line .line-row {display:flex;flex-direction:row;justify-content: space-between;}
.form_wrap2 .one-line .line-row .danwe_txt2 {width:25px;color:#999;margin-left:3px;text-align:right;margin-top:10px;}
.form_wrap2 .one-line.line-left-row {display:flex;flex-direction:row;justify-content: left;margin-top:10px;}
.form_wrap2 .one-line .line-left-row {display:flex;flex-direction:row;justify-content: left;margin-top:10px;}

.form_wrap2 .one-line.line-row {display:flex;flex-direction:row;justify-content: space-between;}
.form_wrap2 .one-line.line-row .two-line {width:calc((100% - 30px)/2);display:flex;flex-direction:row;}
.form_wrap2 .one-line.line-row .two-line .two-num {width:calc(100% - 30px);text-align:right;}
.form_wrap2 .one-line.line-row .two-line .danwe_txt2 {width:25px;color:#999;margin-left:3px;text-align:right;margin-top:10px;}
.form_wrap2 .one-line.line-row .mid_bar03{padding:0 3px;color:#ccc;margin-top:10px;}
.form_wrap2 .one-line.line-row .two-line1 {width:30%;display:flex;flex-direction:row;}
.form_wrap2 .one-line.line-row .two-line2 {width:70%;display:flex;flex-direction:row;margin-left:10px;margin-top:10px;}
.form_wrap2 .one-line.line-row .two-line1 .danwe_txt2 {width:25px;color:#999;margin-left:3px;text-align:right;margin-top:10px;}
.form_wrap3 .one-line {margin-left:10%;display:flex;flex-direction:row;justify-content:right;margin-bottom:10px;}
.form_wrap3 .one-line li {margin-bottom:10px;margin-right:7px;margin-top:0px}

.form_wrap2 .one-line .line-row .two-date {width:calc(100% - 65px);}
.form_wrap2 .one-line .line-row .two-select {width:65px;}
.form_wrap2 .one-line .line-row .two-subway {width:calc((100% - 20px)/2);}

.form_wrap22 {padding-bottom:10px;display:flex;flex-direction:row;justify-content: space-between;}

.layer-pop.layer-pop03 .btn_style_yes {text-align: center;border-radius:30px;}
input.ip_nowrite{background:none;border-radius:#E9E9E9}

.has_btn{position: relative;}
.has_btn .fn_timer {color:red;font-size:1em;position:absolute;width:60px;top:10px;right:85px;}
/* .has_btn .ph_num_dan3 {width: calc(100% - 110px);margin:0} */
.has_btn input {width: calc(100% - 110px);margin:0}
.has_btn input.disable {pointer-events: none;background-color: #E9E9E9;}
.has_btn select.disable {pointer-events: none;background-color: #E9E9E9;}
.w_s_btn{position: absolute; right: 0; top: 0px; width: 100px; background: #454E62; border-radius: 5px; padding:11px 0; text-align: center; color: #fff; font-size: 13px;border: 1px solid #454E62;box-sizing: border-box;}
.w_s_btn.c_gray {background-color: #9f9f9f;color:#fff;border: 1px solid #868686;}
.w_s_btn.c_white {background-color: #fff;border: 1px solid #454E62;box-sizing: border-box;color:#454E62;}

/*이미지업로드(썸네일O)*/
.input_img_wrap{margin-bottom:20px;}
.img_upload_area{width:120px;height:156px;margin-bottom:10px;background:#fff; padding:10px;border-radius:5px;border: 1px solid #EBE8E8}
.img_upload {position: relative;background-color: var(--sub-color-);color:#fff;background-repeat: no-repeat;background-position: center;border-radius: 0px;height:120px;background-image:url(../images/plus.png)}
.img_upload .btn_delete {position: absolute;width: 22px;height: 22px;margin: 4px;top: 0;right: 0;z-index: 10;background-image: url(../images/icon_del_btn.png);background-repeat: no-repeat;background-position: center;background-size: 100%;z-index: 10;cursor: pointer;}
.img_upload input {position: absolute;left: 0;top: 0;width: 100% !important;height: 100% !important;opacity: 0;background-color: transparent;z-index: 5;cursor: pointer;}
.img_upload input + .prev_img .item {position: absolute;left: 0;top: 0;width: 100%;height: 100%;background-color: #fff;border: 1px solid #eee;border-radius: 0px;text-align: center;z-index: 10;border-radius:0px;}
.img_upload input + .prev_img .item img {width: 100%;height: 100%;border-radius:0px;}
.img_upload .prev_img .btn_delete {position: absolute;width: 22px;height: 22px;margin: 4px;top: -2px;right: -2px;z-index: 10;background: url(../images/icon_del_btn.png);background-repeat: no-repeat;background-position: center;background-size: 100%;z-index: 10;cursor: pointer;}

/* 부동산+사업자 회원가입 */
.dan2{display: flex;justify-content: space-between;}
.dan2 .terms_chks{width:48%;max-width:350px;}
.ph_num_dan3, .ph_num_dan4{display: flex;align-items: center;}
.ph_num_dan3 .mid_bar, .ph_num_dan4 .mid_bar{display:inline-block;margin:0 5px;}
.cpn02_join_wrap{display: none;}
.ph_num_dan4 input[type=text] {width:calc((100% - 20px)/3);text-align:center;}


/* 회원가입완료 */
.ok_form_wrap{text-align:center;font-size:1.1em;}
.ok_form_wrap img{margin-top:21vw;margin-bottom:20px;}
.ok_form_wrap .top_txt strong{display: block;margin:10px 0 10px;font-size:1.1em;}
.ok_form_wrap .top_txt p{color:#555;margin-bottom:20px;}
.txt_blue{color:#369bff;margin-bottom:70px;}


/* 로그인 */
.join_share_box{padding-top:20px;margin-top:6px;padding-bottom:0px;background-color:var(--sub-color-);}
.join_share_box .joincom {background-color:mistyrose;padding:10px;border-radius:5px;}
.join_share_box .box a{padding:10px 7px;background:#fff; border:1px solid #666;border-radius:5px;width:49%;margin-right:2%;text-align: center;margin-top:10px ;margin-bottom:10px;}
.join_share_box .box a:last-child{margin-right: 0;display:block;}
.join_share_box a.yellow_btn{background:var(--main-color-); border: 1px solid var(--main-color-)}
.join_share_box a.brown_btn{background: #65401e;border:1px solid #666;border-radius:5px;margin-bottom:10px;margin-right:2%;margin-top:10px;padding:10px 7px;text-align:center;width:49%;color:white;}

.join_share_box .box.goshare{background:#b5edff;padding:10px 10px;border-radius:5px;}
.join_share_box .box.goshare button{width: 97%; border:solid 1px #aaa;padding:10px 7px;border-radius:5px;}


.login-mid .yellow_btn{width:100%;background:var(--main-color-);border: 1px solid var(--main-color-);border-radius:5px;}

.login_wrap_box{position:absolute;left:50%;transform:translateX(-50%);top:20%;}
.login_box{background:#fff;width:100%;text-align:center;border:3px solid #fff;padding:20px 0 60px;box-sizing:border-box;padding-top:10vh; }
.login_box h2 img{width:100%;max-width:240px;}

.login_wrap .login_logo{margin-top:70px; width:210px;}
.login_wrap p { display:block;font-size:17px;color:#999999;font-weight:normal;margin-bottom:10px;}
.login_wrap b { display:block;font-size:45px;color:#fff;text-align:left;margin-bottom:40px;}

.login_wrap .terms_chks{margin: 13px 0;}
.login_wrap .terms_chks label{font-size:14px;color:#000}
.login_wrap .input_wrap{position:relative;}
.login_wrap .input_wrap img{position:absolute;left:20px;top:16px;}
.login_wrap input[type=text] ,
.login_wrap input[type=password] {height:54px;line-height:52px;margin:10px 0;padding-left:70px;width:100%;display:block;box-sizing:border-box;border:0;border-radius:5px;font-size:15px;background:#fff;border:1px solid #d7d7d7; border-radius:5px;color:#999999}
.login_wrap input[type=button] { height:54px;line-height:52px;width:100%;display:block;box-sizing:border-box;border:0;border-radius:5px;font-size:18px;color:#fff;background:#3a7afe;text-align:center;cursor:pointer;font-weight:bold;}
.login_wrap input::placeholder{color:#ccc;}

.login_wrap .login_box button { height:50px;line-height:50px;border:0;background:var(--main-color-);display:block;padding-left:15px;font-weight:bold;color:#666666;
margin-bottom:10px;width:100%;border-radius:5px;text-align:center;color:#000;font-size:16px;}

.login_wrap .login_box button:active{background: #FFC700;}
.login_wrap .login_box button:focus{background: #FFC700;}


.login_wrap .s-menu{text-align:center;margin-top:10px;}
.login_wrap .s-menu a{color:#000}
.login_wrap .s-menu a:first-child:after {content: '';display: inline-block;margin: 0 13px;width: 1px;height: 14px;background: #e0e0e0;vertical-align: -2px;}
.login_wrap .btn_st_org02{width:80%;font-size:1.1em;}

/* 아이디찾기 (찾기 공통) */
 .find_bg_gray{background: var(--sub-color-);padding-top:20px; }

/* ★★★★★★★★★★★★★★ 매물등록 ★★★★★★★★★★★★ */

/*   메인 */
.regist_wrap .top_txt_gray{text-align: center;color:#666;margin-bottom: 20px;}

.go_rg_write{display: block;padding: 20px;position: relative;padding:20px 10px;width: 50%;margin-bottom: 10px;box-shadow: 2px 2px 5px 0 rgb(0 0 0 / 10%);border-radius: 5px;background-color: #fff;font-size:1.3em;}

.regist_wrap .top_area {background: url('../images/regist01.png') no-repeat center right 20px;background-color: #fff;padding: 30px 20px;}
.regist_wrap .top_area .big_txt{font-size: 1.8em;margin-bottom: 10px;letter-spacing:-1px;}
.regist_wrap .top_area a{font-weight: bold;}
.regist_wrap .top_area a img{margin-left: 5px;}

.regist_wrap .menu_wrap {display: flex; justify-content: space-evenly;  flex-wrap: wrap;}
.regist_wrap .menu_wrap li{width: 48%;margin-right:2%;margin-bottom: 10px;box-shadow: 2px 2px 5px 0 rgba(0,0,0, 0.1);border-radius: 5px;background-color: #fff;height:140px;}
.regist_wrap .menu_wrap li:nth-child(2n){margin-right: 0;}
.regist_wrap .menu_wrap li a{display: block;padding: 20px;position: relative;height:100px}
.regist_wrap .menu_wrap li a strong{font-size: 1.2em;display: block;}
.regist_wrap .menu_wrap li a span{color: #666;font-size:0.9em; margin-top:7px;display:inline-block;}
.regist_wrap .menu_wrap li a .arrow_img{position: absolute;right:20px; bottom:20px;}

.regist_wrap .banner{margin-top:10px;margin-bottom: 15px;border-radius:5px;box-shadow: 2px 2px 5px 0 rgba(0,0,0, 0.1);display: flex;background-color:var(--main-color-);padding: 20px;display: flex;align-items: center;}
.regist_wrap .banner .txt_wrap {padding-left: 20px;}
.regist_wrap .banner .txt_wrap strong{font-size:1.1em;}
.regist_wrap .banner .txt_wrap span{color:#666;display: block;letter-spacing:-0.5px}
.regist_wrap .banner.banner_what_p{background-color: #454E62;}
.regist_wrap .banner.banner_what_p strong{color:var(--main-color-);}
.regist_wrap .banner.banner_what_p span{color:#ABB1BC}

.regist_wrap .find_bg_gray{margin-top: 10px}

/* 매물등록 */
/*매물 input 스타일*/
.form_wrap .gray_txt_st01{font-size:0.9em;color:#999;padding-left:10px;margin-bottom:5px;margin-top: -7px;}
.form_wrap .gray_txt_st02{font-size:0.9em;color:#999;padding-left:10px;background: url('../images/star_gray.png') no-repeat left;margin-bottom:20px;}
.form_wrap3 {margin-top: 5px;}
.form_wrap3 .has_btn {display: flex;align-items: center;}
.form_wrap3 .has_btn input{width:100%;}
.form_wrap3 .mid_bar02{margin: 0 10px;text-align: center;color:#999;}
.form_wrap3 .has_btn .right_wrap01{width:100px;text-align: right;width: -webkit-fill-available;}
.form_wrap3 .has_btn .right_wrap01 .terms_chks{display: inline-block;}
.has_btn.has_danwe{margin-bottom: 0;}
.has_btn.has_danwe.manwon input{width: calc(100% - 40px);text-align: right;} /*만원*/
.has_btn.has_danwe.manwon .danwe_txt {width:38px;color:#999;margin-left: 3px;text-align: right;} /*만원*/
.has_btn.has_danwe input{width: calc(100% - 30px);text-align: right;} /*개, 평 (한글자)*/
.has_btn.has_danwe .danwe_txt {width:28px;color:#999;margin-left: 3px;text-align: right;} /*개, 평 (한글자)*/
.dan2_danwe .has_btn.has_danwe .danwe_txt{width:40px;}
.dan2_danwe .has_btn.has_danwe input {width: calc(100% - 40px)}
.dan2_danwe .mid_bar02{margin-right:10px;}

.has_btn.has_danwe.has_radio1{display: flex;flex-wrap: wrap;align-items: center;}
.has_btn.has_danwe.has_radio1 .dan4{width:100%;margin-top: 5px;}
.has_btn.has_danwe.has_radio1 input {width: calc(100% - 64px);}
.has_btn.has_danwe.has_radio1 .danwe_txt{width:28px;text-align: center;margin-left: 0;}

.has_btn.has_danwe.has_5_txt{width:calc(49% - 8px)}
.has_btn.has_danwe.has_5_txt.saedae{width:calc(51% - 8px)}
.has_btn.has_danwe.has_5_txt input{width: calc(100% - 74px)}
.has_btn.has_danwe.has_5_txt.saedae input{width: calc(100% - 100px)}
.has_btn.has_danwe.has_5_txt .danwe_txt5{margin-right: 5px;color:#999;}
.mid_bar03{padding:0 3px;color:#ccc}

.has_cal_wrap{border: 1px solid #dbdbdb;position:relative;width:100%;margin: 0 auto;height:44px;border-radius: 5px;box-sizing: border-box;}
.has_cal_wrap input[type=text]{background-color: #fff;border:none;border: 1px solid #e9e9e9;width: 100%;border:none;position:relative;height:42px; line-height:42px;margin-top: 0;margin-bottom: 0;}
.has_cal_wrap input.disable {pointer-events: none;background-color: #E9E9E9;}
.cal_btn{position: absolute;top: 7px;right: 1px;background: #fff;padding: 3px 5px;
	text-align: center;color: #fff;display: inline-block;font-weight: 300;font-size: 13px;width:35px;}

.has_btn.has_calender{position: relative;display: flex;}
.has_btn.has_calender .has_cal_wrap {width: calc(100% - 100px);margin:0}
.has_btn.has_calender select {width:100px;margin-left: 10px;}

.has_btn.has_danwe.has_2_txt input{width: calc(100% - 58px)} /*한줄에 도보만*/
.has_btn.has_danwe.has_2_txt .danwe_txt2{margin-right: 5px;color:#999999}

.has_btn.has_danwe.has_2_txt.dobo_ban input{width: calc(100% - 62px)}
	/*매물 input 스타일*/
	.agree_area .agr_pop01_btn, .agree_area .agr_pop02_btn{position:absolute; right:10px;top:0px;color:#797979;font-size:0.9em;}

	/* 	사진등록 슬라이드+파일첨부 */
	.swiper-container.photo_box_s{padding-bottom:20px;}
	.photo_box_s .img_upload_area{width:92%}
	
	/* 정책 동의 */
	.agree_area .agr_reg01_btn, .agree_area .agr_reg02_btn{position:absolute; right:10px;top:0px;color:#797979;font-size:0.9em;}

	/*부동산 지원금 아이콘 */
	.jiwon_open_btn{width:22px;margin-top:-3px;cursor:pointer;}

/* desc 팝업 */
.desc_popup_st .con_box strong{display:block;font-size:1.3em; margin-bottom:15px;}
.desc_popup_st .con_box .txt01{font-size:1em; color:#666}
.desc_popup_st .con .con_box{margin-top:10px;margin-bottom:20px;}
.desc_popup_st.layer-pop .layer_inner{padding-top:0;}
.desc_popup_st .close_bt_wrap{position:relative}


/* 임시저장 리스트 */
.storage_wrap .strg_box{margin-bottom: 20px;box-shadow: 1px 4px 9px 0px #ddd;background-color: #fff;padding: 20px;position: relative;}
.storage_wrap .del_btn{position: absolute;right:20px;top:35px;z-index:2;padding: 3px 15px;text-align: center;color: #fff;border-radius: 5px;background-color: #666;font-size: 0.9em;cursor:pointer;}
.storage_wrap .tit03{padding-right:60px;padding-bottom:10px;}
.storage_wrap .c_wrap .info01{margin-top:5px}

/* 전문가에게 매물 내놓기 */
.write_form_wrap .tit05{margin-top:20px;margin-bottom: 10px;}
.write_form_wrap .btm_box{padding:30px 20px 50px 80px; background: #fff url('../images/house.png')no-repeat left 20px top 35px;margin: auto 0 0;}
/* .write_form_wrap.bottom {position:fixed;bottom:0px;} */
.write_form_wrap.bottom {display: flex; flex-direction: column; min-height: 80vh;}
.write_form_wrap .btm_box strong{font-weight: bold;font-size:1.2em;}
.write_form_wrap .btm_box p{color:#666;font-size:0.90em;line-height:1.4;margin-top:10px;}

/* 공인중개사 파트너 모집 */
.btm_quick_menu {display:none}
.form_wrap3 .dan_short02 .terms_chks{min-width:57px;}
.form_wrap3 .dan_short02 .right_wrap02{width: calc(100% - 130px);text-align:right;margin-right:0;}
.form_wrap3 .dan_short02 .right_wrap02 input{width:calc(100% - 50px);max-width:100px;text-align:right;}
.no_flex{display:block !important;}
/* 장터 ★★★★★★★★★★★★★★★★★★★★★ */

	/* 장터메인 */
.market_main_wrap .top_wrap{background-color: #fff;border-bottom:1px solid #F4F4F5}
.market_main_wrap .top_wrap .right_arrow{position: absolute;right:20px;top:10px;background: url('../images/dropdown_down.png')no-repeat center;width:40px; height:50px;}
.market_main_wrap .top_wrap.on .right_arrow{background: url('../images/dropdown_up.png')no-repeat center;}
.market_main_wrap .tit06{font-size:1.15em;}
.market_main_wrap .menu_area{background-color: #fff;padding:20px 0;}
.market_main_wrap .menu_area .dan4 li{text-align: center;padding:13px 0;background-color: #F4F4F5; border-radius: 5px;margin-bottom:5px;}
.market_main_wrap .menu_area .dan4 li a{display: block;}
.mk_menu_wrap{margin-bottom:10px;}
.menu_area_last .menu_area{padding:5px 0 5px 0; margin-bottom:1px;}
.menu_area_last .menu_area:last-child{padding-bottom:20px;}

.top_wrap.on .menu_area {transition: all 0.4s ease-in-out;display:block;}
.top_wrap .menu_area {display:none;}
.top_wrap .menu_area .tit06 {margin:10px 0px 0px 0px;}

	/* 리스트 */
.mk_page_title{padding:15px 0;background-color: #fff;}
.mk_main_slide {border-radius: 0 0 100px 0;}
.c_wrap .info01 .c_dong.st02 span{padding: 3px 7px;text-align: center;color: #fff;border-radius: 5px;background-color: #454E62;font-size: 0.9em;color:var(--main-color-);}
.c_rent_type.st02{font-size:1.2em;}
select.no_bd {max-width: 120px;border: none;margin-bottom: 0px;font-size:14px;padding-right:40px;}
.filter_type02_wrap{background-color: #fff;padding:0 0 10px;}
.filter_type02_wrap .sort_box_2{width: 110px;margin-top: 10px;right:-10px}

.info02 > div img{margin-right: 5px;}
.info02 > div {font-size: 0.85em;margin-bottom: 5px;}

.btn_st_A{padding:3px 7px 7px 7px;text-align: center;color: #fff;border-radius: 5px;background-color: var(--main-color-);font-size: 13px;font-weight: normal;color:#000;margin-right:7px;}
.cpy_list_wrap .btn_st_A{vertical-align: 1px;}

.list_type02{border-top: 1px solid var(--border-color-)}
.list_type02 .list_con{background-color: #fff;}
.list_type02 .mk_con{background-color: #fff;border-top: 1px solid var(--border-color-);margin-bottom:5px;box-shadow:1px 4px 9px 0px #ddd;position:relative}
.list_type02 .mk_con > a{display:block;}
.list_type02 .name_wrap{background-color: #fff;border-bottom: 1px solid #F4F4F5;padding:3px 0}
.list_type02 .info_wrap{display:flex;padding:20px 20px ;border-bottom: 1px solid var(--border-color-);justify-content: space-between;}
.list_type02 .info_wrap .img_wrap img{height:166px;width: 100%;border-radius: 10px;object-fit: cover;}
.list_type02 .info_wrap .info_txt {padding:0px 15px;}
.list_type02 .info_wrap .info_txt > div{color:#000;margin-bottom:10px;}
.list_type02 .info_wrap .info_txt > div img{margin-right:5px;color:#000}
.list_type02 .info_wrap .info_txt .desc{color: #666666;font-size: 1em;}
.list_type02 .info_wrap .img_wrap{width:50%;}
.list_type02 .info_txt{width:50%;}

.list_type02 .list_con.more {
  font-size: 1.2em;
  text-align: center;
  background-color: #fff;
  box-shadow: 1px 4px 9px 0 #ddd;
  margin-bottom: 10px;
  padding: 20px;
  position: relative;
  }
  

.sort2_wrap{display: flex;}
.sort2_wrap .sort_box:first-child{margin-right: 10px;}


/* 상세보기 */
.cpy_view_wrap .top_info_wrap{margin-top: 0;  margin-bottom: 20px;padding: 15px 0 25px;background-color: #fff;}
.cpy_view_wrap .desc{color:#666;margin-top:5px;line-height:1.5;font-size:0.95em;}
.c_wrap02 .right_wrap{top:10px}
.flag_wrap{position: absolute;top:-3px;right:20px;z-index:5;}
.flag_wrap2{position: absolute;top:-3px;right:20px;z-index:5;background-color:#FFDE00;width:130px;height:30px;border:solid 2px #fff;
            border-bottom-left-radius:20px;border-bottom-right-radius:20px;box-shadow:0px 3px 3px 0px gray;}
.flag_wrap2 .flag_text {font-size:16px; color:#fff; font-weight:bold; line-height:28px;background-color: transparent;}
.cpy_view_wrap .mid_info_wrap{margin-top: 10px;background-color: #fff;text-align:center;}
  
.c_wrap02 .date_wrap > div{font-size: 0.9em;color:#999;}
.c_wrap02 .date_wrap > div span{font-size: 0.9em;color:#999;}


.mid_info_wrap .info01 {padding-top: 0px;padding-bottom:20px;}



/* 찜목록 ★★★★★★★★★★★★★★★★★★★★★*/
.tab_menu {position:fixed; top:50px;width:100%;background:var(--sub-color-);z-index:6;}
.tab_menu ul{display: flex;}
.tab_menu ul li{width: 50%;height:54px;line-height: 54px;border-bottom: 5px solid #DDD;text-align:center;}
.tab_menu ul li a{display: block;text-align:center;font-size:1.1em;color:#000}
.tab_menu ul li.on{border-bottom: 5px solid var(--main-color-);}
.tab_menu ul li.on a{font-weight:500;}

.tab_menu ul li span {position:absolute;top:5px;width:26px;height:26px;font-size:12px;font-weight:600;
      line-height:26px;display:inline-block;background-color:red;color:white;border-radius:13px;text-align: center;}

.top_edit_area{background-color: #fff;height:68px; line-height:68px;text-align: right;border-bottom: 1px solid var(--border-color-);}
.top_edit_area .go_edit button{padding-right: 20px;}
.top_edit_area .go_edit button img{margin-top: -3px;padding-left: 5px;}

.btn_area_edit {display: none;height:68px; line-height:68px;}
.btn_area_edit ul{display: flex;justify-content: center;height:100%;align-items: center;}
.btn_area_edit ul li{padding:5px 20px;border-radius:5px;margin: 0 5px;}
.btn_area_edit ul li button{color:#fff;display: block;font-size: 0.9em;}
.btn_area_edit ul li.del_btn{background-color: #454E62;}
.btn_area_edit ul li.ok_btn{background-color: var(--main-color-);}

.edit_on_wrap{position: absolute;top:30px;left:30px;z-index:5;display:none;}
.edit_on_wrap_bg{opacity:0;width:100%;height:100%;background:red;position: absolute;z-index:4;left:-20px; top:-20px;display:none;} /*편집 시 클릭 방지*/

/* 장터 찜목록 */
.list_type02 .edit_on_wrap{top:10px;left:10px;}


/* 채팅 */
.chat_list_wrap .tab_menu{top:50px}

.btm_quick_menu .q_open_btn{display:none;}
.list_type02 .chat_con{background-color: #fff;margin-bottom:5px;box-shadow:1px 4px 9px 0px #ddd;position:relative;display:flex;padding:20px 20px ;border-bottom: 1px solid var(--border-color-);justify-content: flex-start;}
.list_type02 .chat_con:first-child{border-top:1px solid #fff;}
.list_type02 .chat_con > a{display:block;}
.list_type02 .chat_con .img_wrap{width: 25%;max-height:100px;text-align:center}
.list_type02 .chat_con .img_wrap img{max-height: 70px;object-fit: cover;height: 100%;width: 100%;border-radius:10px;}
.list_type02 .chat_con .box {padding:0px 0px 0px 15px;width:70%}
.list_type02 .chat_con .box .tit_txt{font-weight: bold; font-size: 1.3em;margin-bottom:2px;text-overflow: ellipsis;overflow:hidden;width:100%;
      white-space:nowrap;display: -webkit-box;line-height: 22px;max-height: 22px;-webkit-line-clamp: 1;display: inline-block;}
.list_type02 .chat_con .box .date_wrap{font-size: 0.9em; color:#666;margin-top:10px;}
.list_type02 .chat_con .box > div{color:#000;}
.list_type02 .chat_con .box > div img{margin-right:5px;color:#000}
.list_type02 .chat_con .chay_out_btn{position: absolute;right:20px; bottom:20px;z-index: 2}
.list_type02 .chat_con .chay_out_btn img{margin-top:-2px;margin-left:3px}
.list_type02 .chat_con .readyet {position:absolute;top:5px;right:5px;width:10px;height:10px;border-radius:5px;background-color:#ddd;}
.list_type02 .chat_con .readyet.active {position:absolute;top:5px;right:5px;width:26px;height:26px;font-size:12px;font-weight:600;
  line-height:26px;display:inline-block;background-color:red;color:white;border-radius:13px;text-align: center;}
.list_type02 .chat_con .readyet.active span {color:#fff; font-weight:500;}
/* 채팅방 */
.pop_img_wrap{padding-top:50px;}

.chat_top_wrap{width:100%;display:block;z-index:3;border-bottom:1px solid #E9E9E9}
.chat_top_wrap{max-width:800px;margin: 0 auto;height:140px;max-height:140px;position:fixed;}
.chat_top:first-child{border-top:1px solid #fff;}
.chat_top > a{display:block;}
.chat_top{padding:20px 10px 0px 10px;background-color: var(--sub-color-);position:relative;}
.chat_top_inner{display:flex;justify-content: flex-start;}
.chat_top .img_wrap{height: 100px;max-height: 100px;padding: 0 10px;width: 100px;text-align: center;}
.chat_top .img_wrap img{border-radius: 10px;max-height: 100px;height: 100%;object-fit: cover;vertical-align: middle;width: 100%;}
.chat_top .box {padding:0px 10px 0px 0px;width:calc(70% - 50px)}
.chat_top .box .c_name{font-weight:bold; font-size:1.2em;margin-bottom:5px;}
.chat_top .box .c_name a{display:inline-block;}
.chat_top .box .c_name img{margin-top:-10px; margin-left:5px}
.chat_top .box a > div{color:#000;}
.chat_top .box a > div img{margin-right:5px;color:#000}
.chat_top .fnc_wrap{position: absolute;right:10px; top:10px;z-index: 2;display:flex;}
.chat_top .fnc_wrap li{padding-left: 5px;}
.chat_top .fnc_below{position: absolute;right:20px; bottom:10px;z-index: 2;}

.chat_con_wrap{background-color:#ffffff; padding-top:190px;}
.chat_top_wrap .start_msg{display:flex;justify-content: space-between;background-color:#eee; padding:10px;text-align:left;height:20px;}
.chat_top_wrap .start_msg > span {color:red;}
.chat_con_wrap .chat {min-height:calc(100vh - 300px);overflow-y:hidden;padding-bottom:50px;}
.chat_con_wrap .chat .msg_box { position: relative; margin:0px 10px 5px 10px;}
.chat_con_wrap .chat .msg_box .msg { margin-bottom:3px;line-height: 1.3em; display: block;width: auto;word-break: break-all;font-size:larger;font-weight: 400;margin-top:5px}
.chat_con_wrap .chat .msg_box .msg span{display:inline-block;}
.chat_con_wrap .chat .msg_box .msg img {border-radius:10px; overflow:hidden; display:inline-block; vertical-align:top;}
.chat_con_wrap .chat .msg_box .msg_text span{background: var(--sub-color-); padding: 12px; border-radius: 10px; white-space: pre-line; }
.chat_con_wrap .chat .msg_box .img_wrap{display:block;width:60%;}
.chat_con_wrap .chat .msg_box .time { font-size: 12px; font-weight: 300; color: #ccc; }

.chat_con_wrap .chat .msg_box.send_msg .msg_text span.read_state{ display: inline-block; vertical-align: bottom; font-size: 11px; font-weight: 300; color: #ccc; background:none;padding:0 5px 5px 0px;}

.chat_con_wrap .chat .msg_box.receive_msg {width:100%;display:flex;flex-direction: column;align-items: flex-start;}
.chat_con_wrap .chat .msg_box.receive_msg .name { left: 50px;margin-left: 10px; color:#868686; }
.chat_con_wrap .chat .msg_box.receive_msg .time { padding-left: 5px;text-align:left;}
.chat_con_wrap .chat .msg_box.receive_msg .img_wrap {text-align:left;}

.chat_con_wrap .chat .msg_box.send_msg {width:98%;display:flex;flex-direction: column;align-items: flex-end}
.chat_con_wrap .chat .msg_box.send_msg .name { right: 50px;margin-right: 10px; color:#868686;}
.chat_con_wrap .chat .msg_box.send_msg .msg_text {width:70%;display:inline-block;text-align: right;padding-right: 10px;}
.chat_con_wrap .chat .msg_box.send_msg .msg_text span{color: #000; background:gold;text-align:left}
.chat_con_wrap .chat .msg_box.send_msg .time { padding-right: 10px;margin-bottom:5px;text-align:right;color:#C2C2C2;}
.chat_con_wrap .chat .msg_box.send_msg .img_wrap img {width:98%;text-align:right;margin-right: 10px;border-radius: 10px;}


/* .receive_msg + .send_msg,
.send_msg + .receive_msg { margin-top: 30px; } */

.msg_send_wrap{ position: fixed; bottom:0;width:100%;background:#eee;padding:5px 0;border-top:1px solid var(--border-color-)}
.msg_send {position: relative;display:flex;align-items: center;}
.msg_send input { height: 40px; }
.msg_send .msg_fd_wrap{width: calc(100% - 120px);padding-left: 0px; }
.msg_send .msg_fd_wrap input{border:none;}
.msg_send .file_send, .msg_send .btn_send { width: 60px; height: 40px; border-radius: 5px; overflow: hidden; background-position: center;}

.file_send {background: url(../images/plus_y.png) no-repeat center 5px;}
.btn_send {background: url(../images/send_msg.png) no-repeat center 5px;}
.file_btn { position: relative; }
.file_btn input { position: absolute; left: 0; top: 0; width: 100%; height: 100%; z-index: 1; opacity: 0; }
.file_btn .btn { line-height: 45px; }

    
/* 마이페이지 ★★★★★★★★★★★★★★★★★★★★★★★★★*/

/* 마이페이지 메인  */
.mypage_wrap .center_wrap{height:calc(100vh - 140px);padding-bottom:250px;}
.mypage_wrap ul.menu li{border-bottom:1px solid var(--border-color-)}
.mypage_wrap ul.menu li a img {margin-right:13px;}
.mypage_wrap ul.menu li a{display: block;height:62px;line-height:62px;font-weight:500; font-size:1.2em;background: url('../images/arrow_right_off.png') no-repeat right; }
.mypage_wrap .no_ad{text-align:center;}
.mypage_wrap .no_ad img{display:inline-block; margin: 50px 0 20px;}
.mypage_wrap .no_ad .top_txt{margin-bottom:50px;font-size:1.2em;}
.mypage_wrap .pp_top_text_tp01 a{ margin: 0px auto 10px;font-size: 1.05em; font-weight: 400; color: var(--main-color-);text-decoration: underline; }
.mypage_wrap .pp_top_text_tp01 a img{margin-top:-3px;}


/* 정보수정 */
input.fix_input {background: #E9E9E9;border: 1px solid #F8F8F8;pointer-events: none;}
.bye_btn{width:100%;text-align:left;margin-bottom:40px;}
.bye_btn:after{clear:both; display:block; content:' ';}
.bye_btn button{border:none;font-size:0.95em; color:#797979;background:none;}
.goodbye_popup .dan2{justify-content: center;}
.goodbye_popup .dan2 li{padding:0 30px 0 0px; margin-top:10px;}
.goodbye_popup .dan2 .terms_chk35:first-child{margin-right:20px;}

/* 내 등록 모두의 장터 */
.bd_r_gray{border-right:1px solid #E9E9E9}
.my_market_wrap .tit07_3{margin-top:30px;}
.like_list01_wrap .c_wrap02{margin-bottom:20px;}
/* 내 등록 둥지 */
.c_hash2{margin-bottom: 20px;}
.c_hash2 ul li{color:#666;display: inline-block;padding: 2px 7px;text-align: center;border-radius: 5px;background-color: #fff;border: 1px solid #ccc;font-size:0.9em; margin-top:5px;}
.c_hash2 ul li:last-child span{display: none;}
.blind_bg{position: absolute;width:100%;height:100%;background: rgba(0,0,0, .7);top:0;left:0;display: none;text-align: center;}
.blind_bg.on{display: block;z-index: 3}
.blind_bg .txt_wrap{position: absolute;top:50%;transform: translateY(-50%);    width: 100%;}
.blind_bg .txt_wrap strong{color:#fff;margin-bottom: 15px;display: block;font-size: 1.3em;}
.blind_bg .txt_wrap span{color: #fff;}

/* 파트너스결제관리(프리미엄광고신청) */
.ad_pay_list_wrap .tab_menu{position:static;border-top:1px solid #E9E9E9;background-color: var(--sub-color-)}
.ad_pay_list_wrap .go_ad_pay{background-color: var(--sub-color-);padding:20px 0;}
.ad_pay_list_wrap .go_ad_pay a{display: block;background-color: #454E62;padding: 17px 0px;text-align: center;border-radius: 5px;color: #fff;font-size: 1.15em;font-weight:bold;}
.ad_pay_list_wrap table {margin-top:15px;}
.state02{color:#E51D1D;}
.state03{color:#0067B5;}

.ad_apply_con_box .btn{display:inline;background-color: #96602E;padding:2px 15px;margin-right:10px;color: #fff;font-size:13px;font-weight:normal;}
.add_area_btn{position: absolute;right: 0;top: 0px;width: 42px;border-radius: 5px;text-align: center;color: #fff;font-size: 13px;box-sizing: border-box;}
.has_btn .ph_num_dan3 {width: calc(100% - 58px);margin: 0;}
.ad_apply_wrap ul.sel_area{display:flex;flex-wrap: wrap;}
.ad_apply_wrap ul.sel_area li{margin-right:15px;padding: 3px 7px;margin: 0 8px 5px 0;border-radius: 20px;background-color: #eee;color: #444;font-size: 0.9em;font-weight: 500;}
.ad_apply_wrap ul.sel_area li img{width:16px;margin-top:-2px}

/* 파트너스결제관리(프리미엄광고신청-결제하기) */
.ad_con_box{padding:10px;border-top: 1px solid var(--border-color-);border-bottom: 1px solid var(--border-color-);margin-top: 5px;margin-bottom: 20px;line-height: 1.8;font-size:0.95em;}
.ad_con_box .pay_mtd_wrap{margin-top: 5px;}
.ad_con_box .btn{display:inline;background-color: #FFFAD6;padding:4px 10px;margin-right:10px;color: #000;font-size:13px;font-weight:normal;}

/* 파트너스결제관리(프리미엄광고신청-결제완료) */
.ok_form_wrap .top_wrap{background-color: var(--sub-color-);padding-bottom:80px;}
.ok_form_wrap.ad_pay_ok_wrap img{margin-top:80px;}
.ok_form_wrap .pay_ok_list{text-align:left;margin-bottom:40px;}
.pay_ok_list {background-color: #fff;padding:20px 0}

/* 파트너스결제관리(결제내역보기) */
.ok_form_wrap .top_wrap{background-color: var(--sub-color-);padding-bottom:80px;}
.ok_form_wrap.ad_pay_ok_wrap img{margin-top:80px;}
.ok_form_wrap .pay_ok_list{text-align:left;margin-bottom:40px;}
.pay_ok_list {background-color: #fff;padding:20px 0}

/* 파트너스결제관리(결제내역보기-이용권구매_둥지) */
.tb_cal_wrap .month { font-size:1.2em;font-weight:bold;margin-top:20px;padding-bottom:10px; text-align: center;display:flex;align-items:center;justify-content: center;}
.tb_cal_wrap .month strong{position:relative;font-size:1.3em;}
.pass_type_wrap ul{display: flex;flex-wrap: wrap;}
.pass_type_wrap ul li{position: relative;width: calc(48.5% - 30px);border-radius: 10px;background-color: var(--sub-color2-);padding: 15px;margin-bottom:10px;}
.pass_type_wrap ul li:nth-child(2n-1){margin-right:3%;}
.pass_type_wrap ul li .perct{position: absolute;right:-5px;top:-5px;background-color: #E51D1D;border-radius: 20px;padding: 2px 10px 5px 10px;font-size: 0.85em;}
.pass_type_wrap ul li .perct span{color:#fff;}
.pass_type_wrap ul li .dung_num{font-size: 1em; font-weight: bold;margin-bottom: 10px;}
.pass_type_wrap .price_wrap{text-align: right;}
.pass_type_wrap .price_wrap .p01{color:#999999;}
.pass_type_wrap .price_wrap .p01.no_price{text-decoration: line-through;}
.pass_type_wrap .price_wrap .p02{color:#E51D1D;}
.pass_type_wrap ul li.on{background-color: var(--main-color-);}
.pass_type_wrap ul li.on .price_wrap .p01{color:#8D7A00;}
.buy_con_box {padding: 10px;border-top: 1px solid var(--border-color-);border-bottom: 1px solid var(--border-color-);margin-top: 5px;margin-bottom: 20px;line-height: 1.8;font-size: 0.95em;color:#666;text-align: right;}
.buy_con_box span.tt_price{margin-left: 10px; font-size:1.3em; font-weight: bold;color:#E51D1D;}

.use_period_wrap .perct{position: absolute;top:-11px;right:0px;background-color: #E51D1D;border-radius: 20px;padding: 2px 10px 5px 10px;font-size: 0.85em;z-index:2;}
.use_period_wrap .perct span{ color:#fff;font-weight:bold;}


/* 파트너스결제관리(결제내역보기-이용권구매_장터) */
.buy_market_wrap .pass_type_wrap ul li{width:calc(31% - 30px);margin-right:2%}
.buy_market_wrap .pass_type_wrap ul li:last-child{margin-right:0;}
.buy_market_wrap .pass_type_wrap ul li:nth-child(2n-1) {margin-right: 2%;}


/* 파트너스결제관리(결제내역보기-이용권구매_완료) */


/* faq */
.faq_wrap .top_wrap{background-color: #fff;border-bottom:1px solid #F4F4F5;padding:20px 0}
.faq_wrap .top_wrap .right_arrow{position: absolute;right:20px;top:0px;background: url('../images/dropdown_down.png')no-repeat center;width:40px; height:50px;}
.faq_wrap .top_wrap.on .right_arrow{background: url('../images/dropdown_up.png')no-repeat center;}
.faq_wrap .tit03{padding:0;font-size:1.15em;}
.faq_wrap .date{font-size: 0.9em; color:#999;margin-top:5px;}
.faq_wrap .txt_con{background-color: #fff;padding:20px 0;color:#666;display:none;}
.f_con{margin-bottom:5px;}
.faq_wrap .txt_con .subtitle {margin-left:30px;font-size:1.0em;color:#000;}
.faq_wrap .txt_con .otoo_comment, .faq_wrap .txt_con .otoo_answer
            {width:calc(100% - 60px);margin:10px 20px;padding:5px 10px; border:solid 1 #eee;border-radius:10px;background-color:#e2e2e2;}
.faq_wrap .txt_con .otoo_answer {background-color:#FFFAD5;}
/* 문의내역 */
.inquiry_wrap input::placeholder{color:#ccc}
.inquiry_wrap .tit{margin:10px 0 5px;}
button.inquery_ok_btn {display: block;width:100%;background-color: #454E62;padding: 17px 0px;text-align: center;border-radius: 5px;color: #fff;font-size: 1.15em;font-weight: bold;}
.my_inq_wrap .tit06{margin: 20px 0;}
.inquiry_wrap .tit06{margin-top:30px;}

/* 개인정보처리방침+이용약관 */
.agree_wrap{white-space: pre-line;background:#fff}

/* ★★★★★★★★★★★★★★ 여기까지 했음 ★★★★★★★★★★★★ */
/* 스크롤커스텀 */
.rg_filter{scrollbar-color: #d4aa70 #e4e4e4;scrollbar-width: thin;}
.rg_filter::-webkit-scrollbar {width: 5px;}
.rg_filter::-webkit-scrollbar-track {background-color: #fcfcfc;border-radius: 100px;} /* 뒷배경*/ 
.rg_filter::-webkit-scrollbar-thumb {border-radius: 100px; background:#787878;box-shadow: inset 2px 2px 5px 0 rgba(255,255,255, 0.5);} /*막대*/
.rg_filter ul{display: flex;flex-wrap: wrap;padding: 5px 10px;}
.rg_filter ul li{height:44px;line-height: 44px;text-align:center}
.rg_filter ul li.on a{background-color: #d8e2f9;}
.rg_filter ul li a{display:inline-block;width:90%;border-radius:10px;text-align: center;font-weight:400;color:#414141;font-size:1em;height: 90%;line-height: 2.8;}
.rg_filter ul li a.on{background-color: #d8e2f9;}
.rg_filter01{width: 30%;}
.rg_filter02{width: 70%;}
.rg_filter01 ul li{width: 100%;}
.rg_filter02 ul li{width: 33.3%;}
.filter_select_wrap{display: flex;width:100%;padding:8px 10px;background-color: #f2f5fe;border-top: 1px solid #CDCDCD;}
.filter_select_wrap ul{display: flex;width:88%;align-items: center;flex-wrap: wrap;}
.filter_select_wrap ul li{padding:0 10px;color:#5E5E5E}
.filter_select_wrap ul li img{cursor:pointer;margin-top:-2px;margin-left:5px;width:16px;}
.filter_select_wrap .go_search_btn{background:var(--main-color-);border-radius:10px;padding:5px;font-size:0.9em;min-width:110px;text-align:center;height:19px;}
.filter_select_wrap .go_search_btn a{color:#fff; vertical-align: bottom;}
.side_ad_banner .go_cpm_join{display:block;margin-top: 7px; background-color: #4e576d;color:#fff;font-size: 16px;font-weight: 400;border-radius: 10px;text-align: center;padding: 30px 0;}


    
/* 설정 */
.setting_wrap .tit02{padding: 20px 0;border-bottom: 1px solid #E9E9E9;font-size:1.2em;}
.setting_wrap p { display:inline-block;}
.setting_wrap .top_wrap ul{padding:10px 5%;}
.setting_wrap .top_wrap ul li{height:42px; line-height:42px;position:relative;font-size:1em;}
.setting_wrap .box{box-shadow: 1px 4px 9px 0px #ddd;background-color: #fff;padding-bottom:5px;}
.txt_right{position:absolute; right:0; top:10px;}

.switchthree {background: #454E62; border: medium none;border-radius: 8px;height: 17px;width: 40px;line-height:17px;font-weight:500;  font-size:0;margin-top:0px!important;}
.switchthree span { display:inline-block;width:45%;font-size:0;}
.switchthree:after {    box-shadow: 1px 1px 10px 0px #ccc;background:#fff;border-radius: 50%;content: "";height: 20px;left: -1px;position: absolute;top: -2px;transition-duration: 300ms;width: 20px;z-index: 2;}
.switchthree:before {/* background: none repeat scroll 0 0 #ececec; */border-radius: 8px;content: "";height: 15px;left:0px;position: absolute;top: 0px;width:20px;z-index: 1;}
.switchthreeOn, .switchthreeOn:before {background: var(--main-color-) !important;}
.switchthreeOn:after{left:20px !important;border:0px solid #c8e6f5;background:#fff;box-shadow: 0 2px 5px 1px #ddd}
.switch-button,.switchBig, .switchthree, .switchfour {z-index: 0;margin: 0;padding: 0;appearance: none;border: none;cursor: pointer;position: relative;}
.switch-label   input { display:none;}

.setting_wrap ul.menu {padding-bottom:10px;}
.setting_wrap ul.menu li button{width:100%; text-align:left;display: block;height:42px;line-height:42px;font-weight:400; font-size:1em;background: url('../images/arrow_right_off.png') no-repeat right; }



/* 파일첨부 */
.filebox .upload-name {border-radius:3px;display: inline-block;height: 42px;padding: 0 9px;vertical-align: middle;color:#999999;border:none;width: calc(100% - 130px);border: 1px solid #E9E9E9;margin-right:5px}
.input-file-button{padding: 6px 25px;background-color:#454E62;border-radius: 4px;color: white;cursor: pointer;}
.filebox label {width: 100px;text-align: center;background-color: #454E62;color:#fff;padding: 10px 0;display: inline-block;border-radius: 5px;vertical-align: middle;cursor:pointer;}
.filebox input[type="file"] {position: absolute;width: 0;height: 0;padding: 0;overflow: hidden;border: 0;}

.filebox .upload-name.ip_readonly{background:none;}

.no-scroll {
  -ms-overflow-style: none; /* IE, Edge */
  scrollbar-width: none; /* Firefox */
}
.no-scroll::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

/* 퀵메뉴 */
.btm_quick_menu{position: fixed;bottom: 100px;right: 5px;width: 70px;text-align: center;font-weight: bold;z-index:5;cursor:pointer;}
.btm_quick_menu li img{border-radius:50%;}

/* 팝업 1000이상 */
@media only screen and (min-width: 800px) {

.layer-pop{left:50%;margin-left:-400px;}
.layer-pop .layer_inner{margin-bottom:150px;height: calc(100vh - 228px);}
.layer-pop.h_auto{left:10%;margin-left:0;padding-bottom:0;}
.layer-pop.h_auto .layer_inner{margin-bottom:100px;}
.quick_close_btn{left:50%;margin-left:-400px;}
.full_pop .pop_head{box-shadow:none;border-bottom:1px solid #ddd}

}

/* 팝업 1000이상 */
@media only screen and (min-width: 1000px) {
.layer-pop.h_auto{left:50%;margin-left:-400px;}

}
.trans_type_wrap{display:none;}
/* .sale_type_wrap{display:none;} */

/*둥지매물 - 비공개 주소2*/
.terms_chks .addhidden {width:calc(100% - 100px); float:right;}
/* 둥지사진 */
.list_con.reg_photo {display: flex; flex-direction: row; overflow-x: scroll;}
.list_con.reg_photo .e_wrap {width:210px;margin-right:10px;}
.list_con.reg_photo .e_wrap .img_upload_area {width:190px;height:180px;background:#fff; padding:10px;border-radius:5px;border: 1px solid #EBE8E8}
.list_con.reg_photo .e_wrap .img_upload {position: relative;background-color: var(--sub-color-);color:#fff;background-repeat: no-repeat;background-position: center;border-radius: 0px;height:180px;background-image:url(../images/plus.png)}
.list_con.reg_photo .e_wrap .img_upload .btn_delete {position: absolute;width: 22px;height: 22px;margin: 4px;top: 0;right: 0;z-index: 6;background-image: url(../images/icon_del_btn.png);background-repeat: no-repeat;background-position: center;background-size: 100%;z-index: 10;cursor: pointer;}
.list_con.reg_photo .e_wrap .img_upload input {position: absolute;left: 0;top: 0;width: 100% !important;height: 100% !important;opacity: 0;background-color: transparent;z-index: 5;cursor: pointer;}
.list_con.reg_photo .e_wrap .img_upload input + .prev_img .item {position: absolute;left: 0;top: 0;width: 100%;height: 100%;background-color: #fff;border: 1px solid #eee;border-radius: 0px;text-align: center;z-index: 5;border-radius:0px;}
.list_con.reg_photo .e_wrap .img_upload input + .prev_img .item img {width: 100%;height: 100%;border-radius:0px;}
.list_con.reg_photo .e_wrap .img_upload .prev_img .btn_delete {position: absolute;width: 22px;height: 22px;margin: 4px;top: -2px;right: -2px;z-index: 10;background: url(../images/icon_del_btn.png);background-repeat: no-repeat;background-position: center;background-size: 100%;z-index: 10;cursor: pointer;}

.list_con.reg_photo .e_wrap .image-block-area {width:190px;height:180px;background:#fff; padding:10px;border-radius:5px;border: 1px solid #EBE8E8;position:relative;}
.list_con.reg_photo .e_wrap .image-block-area .image-box {max-width:190px;max-height:180px; text-align: center;overflow:hidden;}
.list_con.reg_photo .e_wrap .image-block-area .request-image {object-fit:cover;min-width:190px;min-height:180px;display:block;margin-left:auto;margin-right:auto}
.list_con.reg_photo .e_wrap .image-block-area .btn-delete-image {width:20px;height:20px;position:absolute;right:2px;top:2px;z-index: 4;cursor:pointer;}

.popDoongjiPhoto {background: rgba(0,0,0,.8);position:fixed;top:0px;left:calc(100% - 360px)/2;z-index: 9998;width: 100%;height: 100%;}
.popDoongjiPhoto .consbox2 {position: relative;width: 360px;max-width: 90vw;margin: 0 auto;height: auto;top: 50%;transform: translateY(-50%);}

.popDoongjiPhoto .consbox2 .btnclose {float:right;}

/* 파트너스 보기 */
.cpy_view_wrap {width:100%;max-height:400px;text-align:center;vertical-align:middle;}
.cpy_view_wrap .img_wrap2 {width:100%;}
.cpy_view_wrap .photo_dsp {width:calc(100% - 20px); max-height:300px;}

.form_wrap .jang_price {width:calc(100% - 30px);text-align:right;}

/* 장터 보기*/
.swiper-container.jangter-photos {max-height:320px}

/* 신고하기 */
.singo_sel textarea.disable {pointer-events: none;background-color: #E9E9E9;}

/* 달력 */
.layer-pop-select-date {width:310px;height:auto;position:absolute;left:calc(50% - 150px);top:200px;background-color:#fff;border:solid 2px #aaa;border-radius:20px;}
.layer-pop-select-date .rdp-dropdown_month{margin-right:10px;}
.layer-pop-select-date .date-right-close {width:300px;text-align:right;}
.layer-pop-select-date .date-right-close img {width:14px;height:14px;cursor:pointer;}
.layer-pop-select-date .rdp {margin-top:0px;}
.date-picker{width:100px;height:30px;text-align:center;border:1px solid #eee;font-size:14px;margin-right:10px;}

.layer-pop.show-pop {width:50%;left:25%;}

.has_btn.has_danwe input.one-line {width:calc(100% - 50px);}
.flex-row{display: flex; flex-direction: row; }
.descript-lines,.descript-lines2 {display:-webkit-box;width:100%;overflow:hidden;text-overflow:ellipsis;-webkit-line-clamp:2;-webkit-box-orient:vertical;}
.descript-lines2 {width:100%;}
.descript-lines4 {display:-webkit-box;width:100%;overflow:hidden;text-overflow:ellipsis;-webkit-line-clamp:4;-webkit-box-orient:vertical;}

.img_upload .prev_img {text-align:center;}
.img_upload .prev_img img {height: 100%;left: 0;object-fit: cover;object-position: center;position: absolute;top: 0;width: 100%;}

.con {max-height:calc(100% - 5px);overflow-y:scroll;}
.con .con_box {margin-top:0px;}
.con .con_box .desc {white-space: pre-wrap;text-align: left;}
.con {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.con::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}
.box .room_info .scl {
  width:100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
  word-wrap: break-word;
  display: -webkit-box;
  line-height:1.2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.quick-button {width:70px;height:70px;}

.popup .layer_bg{
    background: rgba(0,0,0,.4);
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 3;
}

.popup .pop_con{
    background: #fff;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    width: 100%;
    max-width: 320px;
    padding: 30px 20px 15px;
    box-sizing: border-box;
    border-radius: 10px;
}

.popup p{
    font-size: 0.875em;
}

.popup strong{
    display: inline-block;
    font-weight: 700;
    margin: 10px 0 24px;
    letter-spacing: -0.04em;
}

.popup .pop_btn{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.popup .pop_btn a{
    display: block;
    height: 45px;
    line-height: 45px;
    text-align: center;
    border-radius: 10px;
    font-size: 0.875em;
}
.popup .pop_btn .btn_no{    
    width: 36%;   
    height:36px; 
    background: #ededed;
    font-size:1em;    
}
.popup .pop_btn .btn_go{
    width: 60%;
    height:36px; 
    background: var(--main-color-);
    font-weight: 500;
    font-size:1em;
}

.swiper{
  height: 100%;
  padding: 0;
  box-sizing: border-box;
}
.c_wrap .img_wrap{
  height: 100%;
}
.c_wrap .img_wrap img{
  border-radius: 0;
}
.flag_wrap, .flag_wrap2{
  top: -7px;
}

.state02 {cursor:pointer;}
.state02:hover{background-color:#ddd;padding:10px;}

.map-base {width:100%;height:300px;}
.map-overpage {position:absolute;width:100%;height:300px;top:0;left:0;z-index:8;}

.image-control-button {background-color: #ffe400;color:#666;font-size:20px;font-weight:400;z-index:10001;margin-right:10px;}

.pop-prev {    
  width: 20px;
  height: 20px;
  position: absolute;
  left: 20px;
  top: 200px;
  background-color: yellow;
  display: block;
  z-index: 100000;
}

.swiper-pagination .swiper-pagination-bullet {
  width:15px; height:15px;
}

.main_dunggi .slick-list {
  height: 170px;
}

.main_dunggi .slick-slide{
  width:220px;
  height: 170px;
}

.main_dunggi .slick-slide > div{
  height:100%;
}

/* 슬라이드 페이지네이션 */

.main_dunggi .slick-dots{
  bottom: 0;
  background-color: transparent;
}

.main_dunggi .slick-dots li button:before{
  color:#fff;
  opacity: 1;
  font-size: 10px;
}

.main_dunggi .slick-dots li.slick-active button:before{
  color: #ffde00;
  opacity: 1;
}
.main_dunggi .slider-container .c_wrap{
  height: auto;
  max-height: max-content;
}

.main_dunggi .c_wrap .img_wrap{
  width: 100%;
  height: 170px;
}
.main_dunggi .c_wrap .img_wrap2{
  width: 100%;
  height: 170px;
}

.main_dunggi .c_wrap .img_wrap a, .main_dunggi .c_wrap .img_wrap2 a{
  display: block;  
  width: 100%;
  height:100%;
}

.main_dunggi .c_wrap .img_wrap img{
  border-radius: 20px;
  object-fit: cover;
}
.main_dunggi .c_wrap .img_wrap2 img{
  border-radius: 20px;
  width:100%;
  height: 170px;
  object-fit: cover;
}

.c_wrap .img_wrap img.only-one-image {object-fit: cover;width:100%;height:100%;}
.c_wrap .img_wrap3 img{object-fit: cover;width:100%;height:400px;}

.price_wrap{font-size: small;}

.loading-indicator {
  position: absolute; 
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f0f0f0; 
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1; 
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #007bff;
  border-top-color: transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

.img_wrap3 {
  position: relative;
  height: 400px;
}

/* Add this to your CSS file */

.loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #444444; /* Dark grey */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* 로딩 오버레이 스타일 */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 반투명 검은색 배경 */
  z-index: 9999; /* 다른 요소 위에 표시되도록 높은 z-index 설정 */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; /* 로더와 텍스트를 수직으로 배치 */
}

/* 로딩 인디케이터 스타일 */
.loading-indicator2 {
  display: flex;
  flex-direction: column; /* 텍스트를 로더 아래로 위치 */
  justify-content: center;
  align-items: center;
}

.unread-indicator {
  color: gold;
  font-weight: bold;
  margin-right: 10px;
}

.photo-progress div {
  margin-top: 10px;
  font-size: 16px;
  color: #fff; /* Adjust color as needed */
  text-align: center;
}
