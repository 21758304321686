.write-list,
.write-list2 {
  border-collapse: separate;
  border-spacing: 0 10px;
}
.write-list tr,
.write-list2 tr {
  min-height: 50px;
  height: 50px;
  border-bottom: 1px solid #eee;
}
.write-list tr:last-child,
.write-list2 tr:last-child {
  border-bottom: none;
  vertical-align: top;
}
.write-list tr th,
.write-list2 tr th {
  width: 30%;
  padding: 0px 0px 0px 50px;
  text-align: left;
}
.write-list2 tr th {
  width: 20%;
}
.write-list tr td,
.write-list2 tr td {
  width: 70%;
  padding: 0px;
  text-align: left;
}
.write-list2 tr td {
  width: 80%;
}
.write-list .write-input,
.write-list2 .write-input {
  width: 100%;
}
.write-list .write-input input {
  width: 70%;
  min-height: 40px;
}
.write-list2 .write-input input {
  width: 98%;
  min-height: 40px;
}
.write-list .write-date-readonly input {
  pointer-events: none;
  background-color: #eee;
  width: 50%;
}
.write-list .write-radio {
  width: 100%;
  text-align: left;
}
.write-list .write-textarea {
  height: 200px;
  width: 100%;
}
.write-list .write-textarea textarea {
  height: 100%;
  width: 95%;
  border: 1px solid #eee;
  padding: 5px;
}
.write-list2 select {
  width: 200px;
}

.chkf-delete {
  border: 1px solid #ccc;
  width: 18px;
  height: 18px;
  vertical-align: middle;
}
.rad-button {
  border: 1px solid #ccc;
  width: 18px;
  height: 18px;
  vertical-align: middle;
}

.board-list input[type="checkbox"] {
  display: none;
}
.board-list input[type="checkbox"] + label {
  cursor: pointer;
  height: 24px;
  margin-left: 10px;
  padding-left: 24px;
  background: url("../images/check-off.png") left/24px no-repeat;
  background-size: contain;
}
.board-list input[type="checkbox"]:checked + label {
  background-image: url("../images/check-on.png");
}

.write-list .write-radio input[type="radio"] {
  cursor: pointer;
  width: 18px;
  height: 18px;
  margin-left: 10px;
  vertical-align: text-top;
  background: url("../images/check-off.png") left/18px no-repeat;
}
.write-list .write-radio input[type="radio"]:checked {
  background-image: url("../images/check-on.png");
}

.write-list .write-input input.disabled {
  pointer-events: none;
  background-color: #ccc;
}

.write .write-radio input[type="radio"] {
  cursor: pointer;
  width: 18px;
  height: 18px;
  margin-left: 10px;
  vertical-align: text-top;
  background: url("../images/check-off.png") left/18px no-repeat;
}
.write .write-radio input[type="radio"]:checked {
  background-image: url("../images/check-on.png");
}

.write button {
  padding: 10px 20px;
  margin-left: 10px;
}

.reply-block {display:flex;justify-content:space-between;margin-bottom:10px;}
.reply-block .reply-comment {width:70%;max-height:60px;}
.reply-block .reply-button {width:15%;margin-top:10px;}

.reply-list-block {border:solid 2px #eee; border-radius:5px;padding:0px 10px;}
.reply-list-top {display:flex;justify-content:space-between;border-bottom:solid 1px #eee;}
.reply-list-subject {padding:10px 0px;}