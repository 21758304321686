
	@charset "utf-8";
/* 공통 */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap');

*{color:#333;}
body, input[type=text], input[type=password],textarea, #content
{font:400 14px 'Noto Sans KR', 'Malgun Gothic', Dotum, Arial, Helvetica, sans-serif; color:#333; letter-spacing:-0.005em; min-width:320px;max-width:800px;}
body, html, p, div, ul, li , h1, h2, h3, h4, h5, h6, span,br, dl, dt, dd{padding:0; margin:0;}
h1, h2, h3, h4, h5{font-weight:400;}
a{text-decoration:none; color:#333}
a, img{border:none; vertical-align:middle;}
ul, li{list-style:none;}
img{max-width:100%;}
body{max-width:100%;background-color:var(--sub-color-) !important;}

input[type=text]{min-width:auto;}
.center_wrap{/* width:1500px; */ margin:0 auto; position:relative; padding: 10px;}
input[type=text],input[type=number],
input[type=password]{margin-top:2px;border:none;border:1px solid #F8F8F8; height:42px;line-height:40px; padding:0 10px;padding-left:10px; box-sizing:border-box; vertical-align:middle;outline:none;margin-bottom:2px;width:100%;border-radius:5px}
select{border:1px solid #e7e7e7; height:50px;line-height:48px; padding:0 10px; box-sizing:border-box; vertical-align:middle;outline:none;width:130px;font-size:18px;font-weight:500;font-family: 'Noto Sans KR'}
input[type=button]{vertical-align:middle;cursor:pointer;outline:none}
input::placeholder {color:#ccc}
table{border-collapse:collapse; width:100%;}
textarea{width:100%; padding:10px; box-sizing:border-box; border:1px solid #e9e9e9;border-radius:0px;min-height:120px;}
textarea::placeholder {color:#ccc;}
input[type=file]{width:100%; height:35px;}
	#head .btn_search { display:none;}

button {-webkit-appearance: none;-moz-appearance: none;appearance: none; margin: 0;cursor:pointer;
	font-family: "Noto Sans KR", sans-serif;font-size: 1rem;text-align: center;text-decoration: none;display: inline-block;width: auto;border: none;background:none;}

/* input.input_st01{height:44px; line-height:42px;} */
input.input_st_bdb{border:none;border-bottom:1px solid #d3d8dc;border-radius:0}
select{border:none;border:1px solid #e9e9e9; height:42px;line-height:40px; padding:0 12px;padding-left:1px; box-sizing:border-box; vertical-align:middle;outline:none;width:100%;font-size:14px;font-weight:400;
background:none;background: #fff url(../images/select_arrow.png) no-repeat right 10px center;appearance: none;color:#555;font-size:0.95em;padding-left:7px;border-radius:5px;}

/*  #content{margin: 0 auto;padding-top:0px;padding-bottom:100px;background:;min-width:320px;max-width:800px;} */
#content{margin: 50px auto 0;width:100%;overflow-y: hidden;}
#content.subpage{padding-top:70px;}

#pc_pd{margin: 0 auto;padding-top:0px;padding-bottom:100px;background:;min-width:320px;max-width:800px;background:#fff}


/*head*/
#header_fix{padding-top: env(safe-area-inset-top);position: fixed;width: 100%;background: #fff;z-index: 99;top: 0;left: 0;text-align: center;box-shadow:1px -1px 10px 1px #ddd}
#header_fix h1 img{margin: 10px 0;height:30px;}

#head a{color:#2f2f2f;}
#head .logo .mobile,	#head .btn_bar,	#head .btn_home,	.gnb_wrap .bg,#gnb .logo{display:none;}

#head {width:100%;height:62px;position:fixed;top:0px;}
#head:after { content:'';display:block;clear:both}

#head .btn_bar{float:right; margin-top:7px;}
#head .btn_bar img{width:27px;}
#head .btn_search{float:right; margin-top:15px;}
#head .btn_search img{width:30px;}
#head .gnb_wrap { height:40px;}
#head .gnb_wrap > .logo{position:absolute; left:50%; margin-left:-41px; margin-top:0;}
#head .logo .pc{display:none;}	
#head .logo .mobile{width:120px;padding-top:7px;}
#head h1 { display:block;background:#fff;font-size:1.3em;font-weight:bold;}

/* 서브메뉴상단 */
.head-top-wrap {background: #fff;box-shadow:1px -1px 10px 1px #ddd;box-sizing: border-box;height: 50px;left: 0;line-height: 50px;position: fixed;text-align: center;top: 0;width: 100%;z-index: 10;}
.head-top-wrap .top-tit {font-size: 1.4em;font-weight: 700;height: 38px;line-height: 38px;overflow: hidden;padding: 6px 0;position: fixed;top: 0;white-space: nowrap;}
.head-top-wrap .top-tit.blue{background:#f3f9ff;color:#369bff}
.head-top-wrap .btn-pre {z-index:2; position:absolute;left:4%;top:15px; width:26px;height:26px;
	background:url('../images/go_back.svg') no-repeat 50%;
	filter: invert(52%) sepia(64%) saturate(7412%) hue-rotate(341deg) brightness(90%) contrast(96%);}

/* 햄버거메뉴 */
#head .nav {position: fixed;top: 0;right: -350px;width: 300px;height: 100%;border-radius: 30px 0px 0px 30px;background: #fff;padding: 20px;padding-top: 50px;z-index: 10;transition: 0.3s;}
#head .nav_closer { position: absolute; top: 0; right: 0;top: 45px; right: 20px; }
#head .nav .sider .content { overflow: scroll; height: calc(94vh - 100px); }
/* #head  .nav .gnv { padding: 40px 0px; }
#head  .nav .gnv li  img{margin-right:10px;}
#header .bottom { position: absolute; left: 0; bottom: 0; width: calc(100% - 40px); margin: 20px; }*/

#head { z-index: 4; }
#head .top { font-size: 21px; line-height: 1.3em; padding-bottom: 15px; }
#head .login { padding: 5px 10px; font-size: 14px; border: 1px solid #774fc6; border-radius: 4px; display: inline-block; color: #774fc6; }
#head .nav_bg { position: fixed; top: 0; left: 0; width: 100%; height: 100%; z-index: 5; background: rgba(0,0,0,0.6); display: none; }

#head.fiexd .nav_bg { display: block; }
#head.fiexd .nav { right: 0; }

#gnb{float:left; margin-left:120px;}
#gnb > .depth1 > li {float:left; text-align:center; position:relative;}
#gnb > .depth1 > li > a{min-width:165px; margin:0 0; display:block; font-size:24px; padding:25px 0; position:relative;font-weight:bold;}
#gnb > .depth1 > li.active > a:after{content:''; display:block; background:#00c6ff; width:100%; height:3px; position:absolute; bottom:-3px; z-index:998;}
#gnb > .depth1 > li > .depth2{position:absolute; width:100%; background:rgba(255,255,255,.9); top:79px; border-top:3px solid #00c6ff; padding:15px 0 5px; display:none; z-index:998;}
#gnb > .depth1 > li > .depth2 li{margin-bottom:10px;}
#gnb > .depth1 > li > .depth2 a{color:#000; font-size:16px; text-align:center; display:block;}
#gnb > .depth1 > li  .depth3 { display:none;}
#gnb_mobile{display:none;}


.f-menu a { display:inline-block;padding:0 0;font-size:1.1em;line-height:40px;margin-right:5px;color:#333;font-weight:500;}
.f-menu img { float:right;margin-top:10px;display:none;}
.footer-con { padding:5px 0 20px;}
.footer-con .f-logo{  display:inline-block;vertical-align:middle;display:none;}
.footer-con .txt { display:inline-block;margin-left:0;color:#666;font-size:1em;line-height:1.6;font-weight:300;vertical-align:middle;}
.footer-con .right-con{  float:right;display:none;}
.footer-con .right-con select { width:185px;height:35px;line-height:35px;border:1px solid #686868;display:inline-block;margin-right:20px;font-size:14px;}
.footer-con .right-con a { display:inline-block;margin-left:10px;}
.bottom-menu {height:75px;position:fixed;width:100%;bottom:0;left:0;background:#fff;padding-bottom:10px;box-sizing:border-box;z-index:3;box-shadow: 5px -2px 10px 0px rgba(150,150,150,.1)}
.bottom-menu a { float:left;width:20%;text-align:center;display:block;font-size:13px;color:#999;border-right:0px solid #343434;padding-bottom:10px;box-sizing:border-box}
.bottom-menu a img { height:25px;display:block;margin:15px auto 5px;}


.footer{background:#fff; color:#aaa;padding:15px 0 0px;margin-bottom:0px;font-size:0.9em;font-weight:normal;border-top:1px solid #E9E9E9}
.footer a{color:#aaa;font-size:0.9em;margin-top: -6px;display: inline-block;}

.mobile { display:none!important;}
table.mobile { display:none!important;}
.pc { display:block!important;}
table.pc { display:table!important;}

.pc { display:none!important;}
table.pc { display:none!important;}
.mobile { display:block!important;}
table.mobile { display:table!important;}
#left-wrap  { display:none;}
.right-search { display:none;}


.clr{display:block; clear:both; content:'';}

/* etc */
.center_wrap{ padding:0 5%;transition: all .5s}
.center_wrap2 {padding:0 3%;transition: all .5s}
#site_map{display:none;}

@media only screen and (min-width: 800px) {
/* .center_wrap{ padding:0;}
#content{padding:0 5% 100px 5%;max-width: 800px;} */
}


::-webkit-scrollbar {
					/* 스크롤바 전체 영역 */
  width: 20px;
} 
::-webkit-scrollbar-track {
		/* 스크롤이 움직이는 영역  */
		background-color: var(--scroll-color-);
} 
::-webkit-scrollbar-thumb {
		/*  스크롤  */
    background-color: #ababab; 
		border:4px solid var(--scroll-color-);
		border-radius:30px;
} 

::-webkit-scrollbar-corner {
		/*  상하+좌우 스크롤이 만나는 공간   */
		background-color: red;
} 


.hidden {display:none !important;}
.disbale {pointer-events:none;background-color:#eee;}
.back-disbale {background-color:#eee;}

.mobile-bottom-menu.mobile {display:none;width:100%;position:fixed;bottom:0px;left:0;background:#fff; z-index:5;box-shadow:0px -5px 8px 0 #eee;/*border-radius:20px; */}
.mobile-bottom-menu a{ float:left;width:20%;text-align:center;padding:13px 0;font-size:0.9em;color:#999}
.mobile-bottom-menu a:last-child{border-radius:0 20px 0 0 ;}
.mobile-bottom-menu a:first-child{border-radius:20px 0px 0 0 ;}
.mobile-bottom-menu a img { display:block;margin:0 auto 5px;/* width:30px; */}

.mobile-bottom-menu a{position:relative}
.mobile-bottom-menu a img.on{display:none}
.mobile-bottom-menu a.on {background-color:#fff;color:var(--main-color-);font-weight:bold;}/*  background:#05a352; border-top:4px solid #05a352; box-sizing:border-box} */ /*20210609 메뉴활성화추가 */
.mobile-bottom-menu a.on img{display:none}
.mobile-bottom-menu a.on img.on{display:block}
/* .mobile-bottom-menu a.on:after{position:absolute; height:4px; background:#05a352;top:-1px;content:'';width:100%;left:0} */

.mobile-bottom-menu .readyet {
	background-color:#e51d1d;font-size:10px;width:auto;height:auto;border-radius:48px;padding:7px;line-height:0.6;position:absolute;top:2px;right:5px;
}
.mobile-bottom-menu .readyet span {
	color: #fff;
	font-weight: 500;
}
	

.fixinner{ position:fixed !important ;box-shadow: 1px 1px 9px -3px #ddd;transition: top 0.2s ease-in-out;}

input[type=text].datepicker{background: url("../images/btn_calendar.png") no-repeat; background-position: 95% center;background-color:#fff;}

.dan_short2 .terms_chk label {
	display: inline-block;
	position: relative;
	left: 0;
	padding-left: 20px;
	line-height: 15px;
	margin-left: 0;
	cursor: pointer;
	color: #333;
	font-size:14px;
	font-weight: normal;
	vertical-align: top;
}
.dan_short2 .right_wrap02 .career_year {
	width:120px;
	line-height:28px;
	height:28px;
	text-align:center;
}
.dan_short2 .right_wrap02 .career_year.disabled { pointer-events:none;background-color:#ddd;}

.ipt_box input.disable {background-color:#eee;pointer-events: none;}

.datepicker-year {height:30px;line-height: 30px;font-size: 14px;text-align:center;padding-right:20px;}

/* 이미지 보기 */
.poplayerPhoto {
  z-index: 9998;position: fixed;left: 50%;top: 50%;-webkit-transform: translate(-50%,-50%);transform: translate(-50%, -50%);width: 100%;height: 100%;
}

.poplayerPhoto .date-right-close {
	background-color: #ffe400;height:auto;position:absolute;right:5px;top:5px;z-index:100;
}
.poplayerPhoto .date-left-close {
	height:auto;position:absolute;left:5px;top:5px;z-index:100;
}

.display-area{
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	display: block;
	width: 100%;
}


.poplayerYoutube {position: absolute; top: 15vh; left: 10%; z-index: 9998; width: 80%; height: 70vh;}
.poplayerYoutube .date-right-close {position: absolute;right: 0px;top: 0px;z-index: 100;background-color: #ffe400;}
.hide {pointer-events: none;background-color: #eee;}

.poplayerMap {
  height:100%;display:none;background:#fff;position:fixed;left:0;transform:none;top:0%;transform: translateY(0%);z-index:12;max-width:100%;
width:100%;box-sizing:border-box;font-size:16px;text-align:left;font-size:15px;
}

.poplayerMap .date-right-close {
  position: absolute;
  right: 0px; 
  height: auto;
  top: 0px;
	z-index: 100;
	background-color: #ffe400;
}

.transform-component-module_wrapper__SPB86 {
	height:100vh !important;
	background-color:black;

}

input[type=text].disabled {background-color: #ddd;pointer-events: none;}
.input-center {text-align:center;}