.select-list {border-collapse:separate;border-spacing:0 10px;}
.select-list tr {min-height:50px;height:50px;vertical-align:top;border-bottom:1px solid #eee;}
.select-list tr:last-child{border-bottom:none;} 
.select-list tr th {width:20%; padding:10px 0px 10px 50px;text-align:left;}
.select-list tr td {width:20%; padding:10px 0px 10px 0px;text-align:left;}
.select-list .select-items {width:40%;}
.select-list .select-items select {border-radius:4px;display:inline-block;min-height:40px;padding-left:10px;width:100%;border:1px solid #e2e3e5;font-size:16px;box-sizing:border-box;}
.select-list .select-addition {width:8%;}
.select-list .select-addition.sel-label {text-align:right;padding:19px 10px 0px 0px;width:7%;}
.select-list .select-addition2 {width:10%;}
.select-list .select-addition2 > button {margin-right:10px;}

.select-list2 .select-btn-delete, 
.select-list2 .select-btn-new,
.select-list2 .select-btn-add {height:40px;line-height:38px;background:#258afe;min-width:80px;color:#fff;font-size:15px;text-align:center;display:inline-block;font-weight:400;margin:0 2px;box-sizing:border-box;border-radius:4px;border:0;padding:0 10px;cursor:pointer;}
.select-list2 .select-input {width:15%;}
.select-list2 .select-input input {min-height:40px;}

.select-list2 {border-collapse:separate;border-spacing:0 10px;}
.select-list2 tr {min-height:50px;height:50px;vertical-align:top;border-bottom:1px solid #eee;}
.select-list2 tr:last-child{border-bottom:none;} 
.select-list2 tr th {width:15%; padding:10px 0px 10px 50px;text-align:left;}
.select-list2 tr td {width:20%; padding:10px 0px 10px 0px;text-align:left;}
.select-list2 .select-items {width:30%;}
.select-list2 .select-items select {border-radius:4px;display:inline-block;min-height:40px;padding-left:10px;width:100%;border:1px solid #e2e3e5;font-size:16px;box-sizing:border-box;}
.select-list2 .select-addition {width:8%;}
.select-list2 .select-addition.sel-label {text-align:right;padding:19px 10px 0px 0px;width:10%;}
.select-list2 .select-addition2 {width:10%;}
.select-list2 .select-addition2 > button {margin-right:10px;}

.select-list2 .select-btn-delete, 
.select-list2 .select-btn-new,
.select-list2 .select-btn-add {height:40px;line-height:38px;background:#258afe;min-width:80px;color:#fff;font-size:15px;text-align:center;display:inline-block;font-weight:400;margin:0 2px;box-sizing:border-box;border-radius:4px;border:0;padding:0 10px;cursor:pointer;}
.select-list2 .select-input {width:20%;}
.select-list2 .select-input input {min-height:40px;}