/*********************
paging
**********************/
.paging{text-align:center; clear:both; margin-top: 28px;}
.paging a{min-width:33px; padding:0 5px; height:33px; line-height:33px; vertical-align: middle; border:1px solid #e2e2e2; margin-right: 5px; color: #666; font-size: 15px;}
.paging a.selected{background:#fff; color:#000; border-color:#000;}
.paging .btn_first,
.paging .btn_prev,
.paging .btn_next,
.paging .btn_last{border:1px solid #e2e2e2; margin:0 3px; line-height: 28px;}
.paging .btn_prev img,
.paging .btn_next img{opacity: 0.2;}
.paging .btn_prev:hover img,
.paging .btn_next:hover img{opacity: 1;}
.paging .btn_prev{margin-right:27px;}
.paging .btn_next{margin-left:22px;}